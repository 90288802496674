import {useEffect, useState} from "react"  
import { Box, Button, Center, Flex, Grid, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Stack, Text } from "@chakra-ui/react"; 
import { cfg } from "../../dados/dados";
import { hexToRGB, removeHTMLTags } from "../../Function"; 
import { sendForm } from "../../conn/WebPainel";
import InputCampo from "../../components/Input/modelos/InputCampo";
import { FaCheckDouble, FaKey, FaSignInAlt, FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Bt1 } from "../../components/Button/ButtonView";
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import BoxDeposito from "./BoxDeposito";
import { MdEmail } from "react-icons/md";
import { HiIdentification } from "react-icons/hi";


export default function BoxPainelUser({open=true,cmd={},onClose,setBox}){
    const [alerta,setAlerta] = useState({});  
    const [sendLoad,setSendLoad] = useState(null);
    const [situa,setSitua] = useState('L');
    const [viewGame,setViewGame] = useState(null);
    const [error,setError] = useState({})
    const view= {};
    console.log(cmd);
    switch (cmd) { 
        case 'deposit':
            view.size='sm';
            view.mensagem = <BoxDeposito />
        break;
        case 'register':
            view.size='sm';
            view.mensagem = (
                <Stack   w='100%' >
                         
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mt='10px'> 
                            <Text as='span' color={'tema.cor001'} ms='5px' fontWeight='bold'>
                                Registrar conta
                            </Text>  
                        </Flex>
                        <Stack py={'10px'} gap={'0px'}>
                            <InputCampo
                                icone={HiIdentification}
                                tipo='nome'
                                titulo='Nome'
                                name='nome' 
                                placeholder="Digite seu nome"
                                error={''}
                            />
                            <InputCampo
                                icone={MdEmail}
                                tipo='email'
                                titulo='E-mail'
                                name='email' 
                                placeholder="Digite o seu e-mail"
                                error={''}
                            />
                            <InputCampo
                                icone={FaUser}
                                tipo='user'
                                titulo='Usuário'
                                name='login' 
                                placeholder="Digite o login"
                                error={''}
                            />
                            <InputCampo
                                icone={FaKey}
                                tipo='password'
                                titulo='Senha'
                                name='senha1'
                                error={''} 
                                placeholder="Digite a senha"
                            />
                            <InputCampo
                                icone={FaCheckDouble}
                                tipo='password'
                                titulo='Confirmar Senha'
                                name='senha2' 
                                error={''}
                                placeholder="Digite a senha"
                            />
                        </Stack> 
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mb='20px'>
                            <Text onClick={()=>{
                                setBox({cmd:'login',open:true})
                            }}  fontWeight='medium'> 
                                 
                                    Já tenho conta,
                                    <Text as='span' color={'tema.cor002'} ms='5px' fontWeight='bold'>
                                     Fazer login!
                                    </Text>
                                 
                            </Text>
                        </Flex>
                        <Flex
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            maxW='100%'
                            mb='20px'>
                                
                                <Bt1 bg1={ '#000000'} bg2={ '#222222'} leftIcon={<FaSignInAlt />} >Registrar Conta</Bt1>
                        </Flex> 
                </Stack>    
            )
        break;
        case 'login':
            view.size='sm';
            view.mensagem = (
                <Stack   w='100%'>
                 <Center> 
                     <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src={"/assets/"+cfg.site+"/logo_menu.webp"} alt={'Logo '+cfg.nome} />
                 </Center>  
                 <Flex
                     flexDirection='column'
                     justifyContent='center'
                     alignItems='center'
                     maxW='100%'
                     mt='20px'>  
                 </Flex>
                 <Stack py={'10px'} gap={'0px'}  px={'20px'} >
                     <InputCampo
                         icone={FaUser}
                         tipo='user'
                         titulo='Usuário'
                         name='login'
                         placeholder="Digite o login"
                         error={''}
                     />
                     <InputCampo
                         icone={FaKey}
                         tipo='password'
                         titulo='Senha'
                         name='senha'
                         error={''}
                         placeholder="Digite a senha"
                     />
                 </Stack> 
                 <Flex
                     flexDirection='column'
                     justifyContent='center'
                     alignItems='center'
                     maxW='100%'
                     mb='20px'>
                     <Text   onClick={()=>{
                        setBox({cmd:'lost',open:true})
                    }}  fontWeight='medium'>   
                             <Text as='span' color={'tema.cor001'} ms='5px' fontWeight='bold'>
                             Esqueci minha senha!
                             </Text> 
                     </Text>
                 </Flex>
                 <Flex
                     flexDirection='column'
                     justifyContent='center'
                     alignItems='center'
                     maxW='100%'
                     mb='20px'>
                         
                         <Bt1 bg1={ '#000000'} bg2={ '#222222'} leftIcon={<FaSignInAlt />} >Fazer Login</Bt1>
                 </Flex> 
             </Stack> 
            )
        break;    
        case 'lost':
            view.size='sm';
            view.mensagem = (
                <Stack   w='100%'>
                 <Center> 
                     <Image w={{ lg: '300px', xl: '370px', '2xl': '440px' }} objectFit='fill' src={"/assets/"+cfg.site+"/logo_menu.webp"} alt={'Logo '+cfg.nome} />
                 </Center>  
                 <Flex
                     flexDirection='column'
                     justifyContent='center'
                     alignItems='center'
                     maxW='100%'
                     mt='20px'>  
                 </Flex>
                 <Stack py={'10px'} gap={'0px'}  px={'20px'} >
                     <InputCampo
                         icone={MdEmail}
                         tipo='email'
                         titulo='E-mail'
                         name='email'
                         placeholder="Digite E-mail da conta"
                         error={''}
                     /> 
                 </Stack>  
                 <Text onClick={()=>{
                    setBox({cmd:'login',open:true})
                }}  fontWeight='medium'  mb='20px'> 
                        
                        Lembrei a senha,
                        <Text as='span' color={'tema.cor002'} ms='5px' fontWeight='bold'>
                            Fazer login!
                        </Text>
                        
                </Text>
                 <Flex
                     flexDirection='column'
                     justifyContent='center'
                     alignItems='center'
                     maxW='100%'
                     mb='20px'>
                         
                         <Bt1 bg1={ '#000000'} bg2={ '#222222'} leftIcon={<FaSignInAlt />} >Fazer Login</Bt1>
                 </Flex> 
             </Stack> 
            )
        break;    
       
        default:
        break;
    }
 
        return <Modal   isOpen={open}  size={{base:'full',md:view.size}}  onClose={()=>{ 
            onClose()
        }} >
        <ModalOverlay
            bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.5)' }
            backdropFilter='blur(10px) hue-rotate(90deg)'
        /> 
            <ModalContent w={'100%'}  bg={'tema.bg001'}    >
                <ModalCloseButton   zIndex='10' color={'red.500'} _hover={{bg:'red',color:'tema.bg001',opacity:0.5}}/>
                <ModalHeader color={'tema.cor002'} w='auto' pb={'0px'}>{view.titulo}</ModalHeader>
                <ModalBody  color={'tema.cor001'}>
                    <Box pos={'relative'}    as={'form'}  onSubmit={(e)=>{
                        e.preventDefault();   
                        setAlerta({load:true}) 
                        let json = {};  
                        new FormData(e.target).forEach(function(value, prop){ 
                            json[prop] = value;
                        });
                        sendForm({ modulo:'usuario',cmd:cmd,form:json,callback:(result)=>{ 
                            if(result.erro){ 
                                result.fechar =true;
                                result.size = 'md';  
                                setAlerta(result); 
                            }else{  
                                if(cmd=='register'){
                                    setBox({cmd:'deposit',open:true});
                                }else{
                                    onClose();
                                } 
                                
                                setAlerta({close:true});  
                            }
                            
                        }}); 
                    }} > 
                        {view.mensagem}
                    </Box>
                    <BoxWindow param ={alerta} />
                </ModalBody> 
                <ModalFooter />
                {/*  
                {view.mensagem.length>0?<ModalBody  color={'tema.cor001'}>{view.mensagem}</ModalBody> :<ModalBody /> }
                {view.botao.length>0?<ModalFooter   ><Flex gap={'20px'} w={'100%'}  align='center' justify='center'>{view.botao}</Flex></ModalFooter> :<ModalFooter /> } */} 
            </ModalContent>
        </Modal> 
   
}