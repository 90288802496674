import { createIcon } from "@chakra-ui/react";

export const FaBeachTennis = createIcon({
    displayName: "BeachTennis",
    viewBox: "0 0 200 200",
    path: (
      <>
        <path
          fill="currentColor"
          d="M139.44 130.32c3-10.75 12-20.25 12-20.25 16.5-16 18-37 18-37 1.25-58-72.75-58-71.5 0 0 0 1.5 21 18 37 0 0 9 9.5 12 20.25l1.92 6.44h7.25z"
        />
        <path
          fill="currentColor"
          d="M114.05 59.6a2 2 0 11-2-2 2 2 0 012 2zM114.05 66.64a2 2 0 11-2-2 2 2 0 012 2zM114.05 74.26a2 2 0 11-2-2 2 2 0 012 2zM114.05 81.31a2 2 0 11-2-2 2 2 0 012 2zM114.05 88.26a2 2 0 11-2-2 2 2 0 012 2zM155.84 59.6a2 2 0 11-2-2 2 2 0 012 2zM155.84 66.64a2 2 0 11-2-2 2 2 0 012 2zM155.84 74.26a2 2 0 11-2-2 2 2 0 012 2zM155.84 81.31a2 2 0 11-2-2 2 2 0 012 2zM155.84 88.26a2 2 0 11-2-2 2 2 0 012 2zM121 52.31a2 2 0 11-2-2 2 2 0 012 2zM121 59.35a2 2 0 11-2-2 2 2 0 012 2zM121 67a2 2 0 11-2-2 2 2 0 012 2zM121 74a2 2 0 11-2-2 2 2 0 012 2zM121 81a2 2 0 11-2-2 2 2 0 012 2zM121 88.26a2 2 0 11-2-2 2 2 0 012 2zM121 95.31a2 2 0 11-2-2 2 2 0 012 2zM149.09 52.31a2 2 0 11-2-2 2 2 0 012 2zM149.09 59.35a2 2 0 11-2-2 2 2 0 012 2zM149.09 67a2 2 0 11-2-2 2 2 0 012 2zM149.09 74a2 2 0 11-2-2 2 2 0 012 2zM149.09 81a2 2 0 11-2-2 2 2 0 012 2zM149.09 88.26a2 2 0 11-2-2 2 2 0 012 2zM149.09 95.31a2 2 0 11-2-2 2 2 0 012 2z"
        />
        <path
          fill="currentColor"
          d="M133.34 114.78h-5.23s-1.21-.57-2.34-3.61c0 0-1.2-2 5.2-4.07l2.19-.63 2.05.63c6.4 2.06 5.19 4.07 5.19 4.07-1.13 3-2.33 3.61-2.33 3.61h-5.23"
        />
        <path
          fill="currentColor"
          d="M128.57 135.51L128.57 143.51 128.57 166.01 124.94 166.01 124.94 173.64 132.19 173.64 133.98 173.64 141.23 173.64 141.23 166.01 137.61 166.01 137.61 143.51 137.61 135.28"
        />
        <path
          fill="currentColor"
          d="M134.18 127.89c2.32-2.71 3-8.84 3-8.84h-7s.67 6.13 3 8.84l.51.75z"
        />
        <path
          fill="currentColor"
          d="M128.53 146.23l9.33-3.39m-9.3 10.16l9.33-3.39m-9.28 10.14l9.32-3.39"
        />
        <path
          fill="currentColor"
          d="M60.56 76.65c-3 10.75-12 20.25-12 20.25-16.5 16-18 37-18 37-1.25 58 72.75 58 71.5 0 0 0-1.5-21-18-37 0 0-9-9.5-12-20.25l-1.92-6.44h-7.25z"
        />
        <path
          fill="currentColor"
          d="M86 147.37a2 2 0 112 2 2 2 0 01-2-2zM86 140.33a2 2 0 112 2 2 2 0 01-2-2zM86 132.71a2 2 0 112 2 2 2 0 01-2-2zM86 125.66a2 2 0 112 2 2 2 0 01-2-2zM86 118.71a2 2 0 112 2 2 2 0 01-2-2zM44.16 147.37a2 2 0 112 2 2 2 0 01-2-2zM44.16 140.33a2 2 0 112 2 2 2 0 01-2-2zM44.16 132.71a2 2 0 112 2 2 2 0 01-2-2zM44.16 125.66a2 2 0 112 2 2 2 0 01-2-2zM44.16 118.71a2 2 0 112 2 2 2 0 01-2-2zM79 154.66a2 2 0 112 2 2 2 0 01-2-2zM79 147.62a2 2 0 112 2 2 2 0 01-2-2zM79 140a2 2 0 112 2 2 2 0 01-2-2zM79 133a2 2 0 112 2 2 2 0 01-2-2zM79 126a2 2 0 112 2 2 2 0 01-2-2zM79 118.71a2 2 0 112 2 2 2 0 01-2-2zM79 111.66a2 2 0 112 2 2 2 0 01-2-2zM50.91 154.66a2 2 0 112 2 2 2 0 01-2-2zM50.91 147.62a2 2 0 112 2 2 2 0 01-2-2zM50.91 140a2 2 0 112 2 2 2 0 01-2-2zM50.91 133a2 2 0 112 2 2 2 0 01-2-2zM50.91 126a2 2 0 112 2 2 2 0 01-2-2zM50.91 118.71a2 2 0 112 2 2 2 0 01-2-2zM50.91 111.66a2 2 0 112 2 2 2 0 01-2-2z"
        />
        <path
          fill="currentColor"
          d="M66.66 92.19h5.23s1.21.57 2.34 3.61c0 0 1.2 2-5.2 4.07l-2.19.63-2-.63c-6.4-2.05-5.19-4.07-5.19-4.07 1.13-3 2.33-3.61 2.33-3.61h5.23"
        />
        <path
          fill="currentColor"
          d="M71.43 71.46L71.43 63.46 71.43 40.96 75.06 40.96 75.06 33.33 67.81 33.33 66.02 33.33 58.77 33.33 58.77 40.96 62.39 40.96 62.39 63.46 62.39 71.69"
        />
        <path
          fill="currentColor"
          d="M65.82 79.08c-2.32 2.71-3 8.84-3 8.84h7s-.67-6.13-3-8.84l-.51-.75z"
        />
        <path
          fill="currentColor"
          d="M71.47 60.74l-9.33 3.39M71.44 54l-9.33 3.4m9.28-10.15l-9.32 3.39"
        />
      </>
    ),
  });