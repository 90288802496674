import { Button, Flex, Icon, InputGroup, Skeleton, Spacer, Stack, Tag, TagLabel, Text } from "@chakra-ui/react";
import { FaArrowAltCircleRight, FaArrowRight, FaFutbol } from "react-icons/fa";
import { cfg } from "../../../dados/dados";
import { hexToRGB } from "../../../Function"; 
import { IoIosArrowForward } from "react-icons/io"; 
import { getEsporteIcon } from "../../../menu/modelos/MenuEsportesLigas";
import { GP, srvTime } from "../../../conn/WebPainel";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"; 
import { addBet, betD } from "../../../hooks/apostaDados";

function removerAcentos( newStringComAcento ) {
    var string = newStringComAcento;
      var mapaAcentosHex 	= {
          a : /[\xE0-\xE6]/g,
          e : /[\xE8-\xEB]/g,
          i : /[\xEC-\xEF]/g,
          o : /[\xF2-\xF6]/g,
          u : /[\xF9-\xFC]/g,
          c : /\xE7/g,
          n : /\xF1/g
      };

      for ( var letra in mapaAcentosHex ) {
          var expressaoRegular = mapaAcentosHex[letra];
          string = string.replace( expressaoRegular, letra );
      }

      return string;
  }
const BoxApostaLista = function(props){
    const {jogo,flags,oddTs} = props;   
    var link    =   '/'+jogo.esporte+'_'+jogo.codesp+''; 
    link       +=   '/'+jogo.competicao+'_'+jogo.codcom+'';
    link       +=   '/'+jogo.casa1+'-';
    link       +=   'e-'+jogo.casa2+'-';
    link       +=   'vs-'+jogo.fora1+'-';
    link       +=   'e-'+jogo.fora2;
    link       +=   '_'+jogo.id;
    link        =   removerAcentos(link.toLowerCase().replace(' - ','-').replace(/ /g,'-'))

   
    return(
        <Stack w={'100%'} p={'5px'}>
            <Flex w={'100%'}   align={'center'} justify={'flex-start'} gap={'5px'}  fontSize={'14px'} >
                <Icon as={getEsporteIcon(jogo.codesp)} color={'tema.cor002'} fontSize={'16px'}/>
                
                { 
                new Date(jogo.data).getTime()<srvTime?
                jogo.dataI== null?<Text color='whatsapp.500'>{jogo.id} - INICIA EM BREVE</Text>:
                <>
                    <Tag size={'sm'} minW={'50px'}   w={'50px'}alignContent={'center'} justifyContent={'center'} fontSize={'10px'} p={'0 5px'} textAlign={'center'}  fontWeight={'bold'}  variant='outline' colorScheme='whatsapp'> 
                        <TagLabel>AO VIVO</TagLabel>
                    </Tag>
                    <Text color='whatsapp.500'>20'</Text>
                </>
                :<>
                    <Text color='tema.cor002'>{((new Date(jogo.data).getDate())<10?'0'+(new Date(jogo.data).getDate()):new Date(jogo.data).getDate())}/{((new Date(jogo.data).getMonth()+1)<10?'0'+(new Date(jogo.data).getMonth()+1):new Date(jogo.data).getMonth()+1)} {((new Date(jogo.data).getHours())<10?'0'+(new Date(jogo.data).getHours()):new Date(jogo.data).getHours())}H{((new Date(jogo.data).getMinutes())<10?'0'+(new Date(jogo.data).getMinutes()):new Date(jogo.data).getMinutes())}</Text>
                </>
                }
                
                <Spacer/> 
                {typeof flags[jogo.code]!=='undefined'?<Text as={'span'}  className={"fi fi-"+flags[jogo.code].toLowerCase()} mr={'5px'}  color={'rgba('+hexToRGB(cfg.theme.cores.cor001)+',0.8)'}   w='20px' h='16px' borderRadius={'4px'} ></Text>:null}
                <Text  textTransform='uppercase'color={'rgba('+hexToRGB(cfg.theme.cores.cor001)+',0.5)'} noOfLines={'1'} title={jogo.competicao}>{jogo.competicao}</Text>
            </Flex>
            <Stack direction={{base:'column',lg:'row' ,xl:'column' ,'2xl':'row'}}>
                <Flex w={'100%'} color={'tema.cor001'}  align={'center'} justify={'flex-start'} gap={'5px'}  fontSize={'14px'} >
                    <Stack p={0} gap={'0px'}>
                        <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa1}</Text>
                            <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>E</Text>
                            <Text  maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa2}</Text>
                        </Flex>
                        <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora1}</Text>
                            <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>E</Text>
                            <Text maxW={'calc(100% - 10px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora2}</Text>
                        </Flex> 
                    </Stack>
                    <Spacer/>
                    <PlacarAovivo jogo={jogo.id} />
                </Flex>
                <Flex     color={'tema.cor001'}    align={'center'} justify={'flex-end'} gap={'20px'}  fontSize={'14px'} >
                    <Flex>
                        {(typeof oddTs!=='undefined')?
                        
                            <BoxBetODDs oddTs={oddTs} tipo='3' jogo={jogo}  link={link} />:
                            
                            <Skeleton opacity={'0.3'} w='100%'  h={'40px'}></Skeleton> 
                        } 
                     </Flex>
                     <Flex>
                        {(typeof oddTs!=='undefined')?
                        
                            <BoxBetODDs oddTs={oddTs} tipo='4' jogo={jogo}  link={link} />:
                            
                            <Skeleton opacity={'0.3'} w='100%'  h={'40px'}></Skeleton> 
                        } 
                     </Flex>
                     <NavLink to={link}>
                        <Button w={'30px'} fontSize={'14px'} flexDirection={'column'} colorScheme="orange" variant={'outline'}>
                            <Icon fontSize={'18px'} as={IoIosArrowForward}/>
                        </Button>
                     </NavLink> 
                </Flex>
            </Stack> 
        </Stack>
    )
} 
const PlacarAovivo = function(props){
    const {id} = props;
    return null;
    // return <Stack pr={'10px'}>  
    //     <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}>
    //         <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>0</Text> 
    //     </Flex>
    //     <Flex gap={'5px'} align={'flex-start'} justify={'flex-start'}> 
    //         <Text w={'20px'} textTransform={'uppercase'} noOfLines={'1'}>0</Text> 
    //     </Flex> 
    // </Stack>
}
export const BoxBetODDs =function({oddTs,tipo,jogo,link}){   
    const [upD,setUpD] = useState();
    const [odds,setOdds] = useState({}); 
    useEffect(()=>{
        
        var interV = setInterval(()=>{
            
            var up     = '#';  
            oddTs.forEach((o)=>{
                if(tipo == o.t){
                    try {
                        up += GP.odds.dados[o.i].valor+'#'; 
                    } catch (error) {
                        up += 'null#'; 
                    }
                    
                } 
            }); 
            if(upD != up){ 
                console.log(up);
                setUpD(up);
            }  
        },1000)
        return ()=>{
            clearInterval(interV);
        }
    },[upD])  
    var tempO  = 0;
    return <Flex w={'100%'} gap={'5px'}>
        {oddTs.map((o)=>{

            if(tipo == o.t){
                try {
                    tempO = GP.odds.dados[o.i].valor;
                } catch (error) {
                    tempO = null;
                }
                var s = false;
                return <Button onClick={()=>{
                    addBet(o,tipo,jogo,link);
                }}  key={'key'+o.i} w={'100%'} minW={'58px'} fontSize={'14px'} color={s?'tema.cor003':'tema.cor002'}   flexDirection={'column'} colorScheme="orange" variant={'outline'}>
                    <Text color={'tema.cor002'} fontSize={'12px'} >{o.p}</Text>  
                    {tempO== null?
                        <Skeleton opacity={'0.3'}  w='30px' h={'17px'}/>:
                        <Text color={s?'tema.cor001':'tema.cor002'}  >{tempO}</Text>
                    }    
                </Button>
            }else{
                return null
            }
        })} 
    </Flex>
}
export default BoxApostaLista;