import { Box, Button, Flex, Icon, Skeleton, Stack, Text } from "@chakra-ui/react"
import { FaPlay, FaPlayCircle, FaRegPlayCircle } from "react-icons/fa"
import { GP } from "../../conn/WebPainel"
import { useEffect, useState } from "react"
import { BsFillPlayCircleFill, BsPlayCircleFill } from "react-icons/bs"
import { hexToRGB } from "../../Function"
import { cfg } from "../../dados/dados"
import { getEsporteIcon } from "../../menu/modelos/MenuEsportesLigas" 
import BoxApostaLista from "../../pages/Site/PageEsporte/BoxApostaLista"
export const DestaqueAoVivo = function(props){
    
}
export const DestaqueBanner= function(props){
    const {bgT='tema.cor003'}=props 
    return (
        <Flex bg={'tema.bg003'} borderRadius={'lg'}>
            <Flex bg={bgT} p={'5px 20px'} w={'100%'} gap='20px' align={'center'} justify={'space-between'} borderRadius={'lg'}>
                <Stack p={0} gap={0}pb={'5px'}>
                    <Text as='h3'fontSize={'20px'} color={'tema.cor001'}  fontWeight={'bold'} fontFamily={'Barlow'}>GANHE ATÉ R$ 500,00</Text>
                    <Text as='h4'fontSize={'12px'} color={'tema.cor001'}  fontWeight={'bold'}  >Sua primeira recarga vale até R$ 500,00  em bônus</Text>
                </Stack>
                <Button px={'25px'}  variant='solid' colorScheme="orange" bg={'tema.bg001'} >DEPOSITAR</Button> 
            </Flex>
        </Flex>
    )
}
const DestaqueEsportes = function(props){
    const {bgT='tema.cor002'}=props

    const [reload,setReload] = useState(0);   
    const [width, setWidth] = useState(window.innerWidth);
    let lista = null; 
    useEffect(()=>{
        var inter = setInterval(()=>{ 
            if(typeof GP.esporte!=='undefined' && reload!=GP.esporte.up){
                setReload(GP.esporte.up); 
            } 
        },300)
        return ()=>{
            clearInterval(inter);
        }
    },[reload])
    if(typeof GP.esporte!='undefined'){ 
        lista =Object.values(GP.esporte.dados);
        lista.push({ 
            id:'play',
            nome:'Ao Vivo',
            slug:'/aovivo/',
            ordem:0
        })
        lista=lista.sort((a, b)=>{  
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0; 
        });
    }
    // [
        
    // ]
    return (
        <Flex bg={'tema.bg002'} borderRadius={'lg'} p={'5px'} gap={'5px'}>
            {
                lista==null?
                    <Skeleton opacity={'0.3'} w='100%'  h={'60px'}></Skeleton> 
                :
                lista.map((l,i)=>{
                    return (
                        <Button key={'li'+i} h={'60px'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg003)+',0.5)' } flexDirection={'column'} p={'5px 10px'} gap={'5px'} colorScheme="orange" variant={'ghost'} color={'tema.cor001'} _hover={{color:'tema.bg001',bg:'tema.cor001'}}>
                            <Icon as={getEsporteIcon(l.id)} h={'30px'} color={'tema.cor002'} w={'30px'} mt='4px'></Icon>
                            <Text fontSize={'10px'} fontWeight={'500'}  >{l.nome}</Text>
                        </Button>
                    )
                })
            } 
            
            {/* <Flex bg={bgT} p={'5px 20px'} w={'100%'} align={'center'} justify={'space-between'} borderTopRadius={'lg'}>
                
                <Text as='h3' fontWeight={'bold'}>Ao Vivo Play</Text> 
            </Flex> */}
        </Flex>
    )
}
export default DestaqueEsportes;