import { useEffect, useState } from "react";
import { GP, cat, esp, jog, srvTime } from "../../../conn/WebPainel";
import { Avatar, AvatarGroup, Box, Button, Flex, Icon, Img, Skeleton, Spacer, Stack, Text } from "@chakra-ui/react"; 
import { cfg } from "../../../dados/dados";
import { TempoCronometro, hexToRGB } from "../../../Function"; 
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { FaTimes } from "react-icons/fa"; 
import { BiTimer } from "react-icons/bi";
import PlayAoVivo from "../../Maker/PlayAoVivo";
import PlayAoVivoView from "../../../components/Maker/PlayAoVivoView/PlayAoVivoView";
import BoxApostaLista, { BoxBetODDs } from "./BoxApostaLista";
import { sendSocket } from "../../../conn/WebGame";


const DetalheJogo = function(props){
    var flags = {};
    const [limite, setLimite] = useState(5);
    getCountries().forEach((flag)=>{
        flags[getCountryCallingCode(flag)]=flag;
    });
    const {bgT='tema.cor002',jog= 0, titulo=''}=props
    const [reload,setReload] = useState(0);   
    const [width, setWidth] = useState(window.innerWidth);
    let lista = null; 
    useEffect(()=>{
        console.log('loadconexao')
    })
    // let jogos = null; 
    useEffect(()=>{
        var inter = setInterval(()=>{ 
            if(typeof GP.esporte!=='undefined' && typeof GP.partidas!=='undefined' && typeof  GP.aposta!=='undefined' && typeof  GP.market!=='undefined' && typeof  GP.position!=='undefined' && typeof  GP.odds!=='undefined'){
                if( reload!=(GP.esporte.up+GP.partidas.up+GP.aposta.up+GP.market.up+GP.position.up+GP.odds.up)){
                    setReload((GP.esporte.up+GP.partidas.up+GP.aposta.up+GP.market.up+GP.position.up));
                } 
            } 
        },300)
        return ()=>{
            clearInterval(inter);
        }
    },[reload]) 
    let jogo = null;
    if(typeof GP.esporte!='undefined' && typeof GP.partidas!='undefined' && typeof  GP.aposta!=='undefined' && typeof  GP.market!=='undefined' && typeof  GP.position!=='undefined'){ 
        try {
            jogo =GP.partidas.dados[jog];
        } catch (error) {
            
        } 
        sendSocket({cmd:'sendGame',form:jog})
        let jogos = [];
        var add = true;
       
        var bets = {};
        if(jogo !=null){
            Object.values(GP.aposta.dados).forEach((v)=>{
                if(v.codjog == jogo.id){
                    if(typeof bets[v.codmar] == 'undefined'){
                        bets[v.codmar] = {
                            nome:GP.market.dados[v.codmar].descricao,
                            tipo:v.codmar,
                            dados:[]
                        }
                    }
                    bets[v.codmar].dados.push({
                        i   :   v.id, 
                        t   :   v.codmar, 
                        p   :   GP.position.dados[v.codpos].nome,
                        o   :   GP.position.dados[v.codpos].ordem,
                    })
                }  
            }); 
        } 
    }  
    return (
        <>
             {(jogo===null)? <Skeleton opacity={'0.3'} w='100%'  h={'200px'}></Skeleton>:
            <Stack  borderRadius={'lg'}   gap={'10px'}>
               
                {typeof jogo=='undefined'?<Box w='100%'  overflowY={'hidden'} overflowX={'auto'} p='10px 5px' pb='5px' gap={'5px'}>
                    <Text fontSize={'sm'}  fontWeight={'500'} color={'tema.cor001'} >Jogo não existe ou já foi finalizado!</Text>
                </Box>:
                <>
                    <Flex position={'relative'} bg={bgT} align='center' justify='space-between' overflow={'hidden'}  w={'100%'} h={'200px'}   borderRadius={'lg'}>
                        {jogo!=null? 
                        <Stack w={'100%'} gap={ 0}>
                            <Img zIndex={'1'} position={'absolute'} top='0px'  opacity={'0.2'}    objectFit='fill' src={"/assets/"+cfg.site+"/img/sport/"+jogo.esporte+".webp"}  h={{base:'100%' }} minW={'auto'} w={'100%'}/>
                            
                            <Flex zIndex={'2'} textAlign={'center'} fontSize={'16px'} w={'90%'} m={'0 auto'} borderTopRadius={'lg'} bg={'rgba(0,0,0,0.8)'} align={'center'} justify={'center'} color={'tema.cor001'} >
                                
                                    <Icon as={BiTimer} h={'30px'} w={'30px'}/>
                                    <Text lineHeight={'25px'} pt={'5px'} pl={'5px'}>
                                        <TempoCronometro view={'tempo'} time={new Date(jogo.data).getTime()} />
                                    </Text> 
                                
                            </Flex>
                            <Stack position={'relative'} zIndex={'2'} w={'90%'} p={0} gap={0}  bg={'rgba(0,0,0,0.6)'}  m={'0 auto'} borderBottomRadius={'lg'} >
                                <Stack  position={'absolute'} gap={0} w={'100%'} color={'tema.cor001'} pt={'10px'} textShadow={'sm'} textTransform={'uppercase'} align={'center'} justify={'center'} >
                                    
                                    <Text  noOfLines={1}  >{jogo.competicao}</Text> 
                                    <Text  noOfLines={1} fontSize={'12px'} lineHeight={'18px'} mt={'15px'} color={'tema.cor004'}>{new Date(jogo.data).getTime()>new Date().getTime()?'HORÁRIO':'COMEÇOU EM'}</Text>
                                    <Text  noOfLines={1} fontSize={'28px'} lineHeight={'28px'} color={'tema.cor004'}>{jogo.data.split('T')[1].split(':')[0]+':'+jogo.data.split('T')[1].split(':')[1]}</Text>
                                    <Text  noOfLines={1} color={'tema.cor004'} >{jogo.data.split('T')[0]}</Text>
                                </Stack> 
                                <Flex   w={'100%'}  textTransform={'uppercase'}   gap={0}>  
                                    <Stack   w={'50%'} >
                                        <AvatarGroup size='md' max={2} ml='10px' mt='10px' >
                                            <Avatar  name={jogo.casa1} src={'https://demo.beachsports.bet/p/'+jogo.img01} />
                                            {jogo.img02!=null?  <Avatar   name={jogo.casa2} src={'https://demo.beachsports.bet/p/'+jogo.img02}/> :null} 
                                        </AvatarGroup>
                                        <Stack gap={0}  p={'10px'}   w={'100%'}  fontSize={{base:'10px',sm:'14px'}}   color={'tema.cor001'} align={'center'} justify={'center'} textAlign={'left'}>
                                            <Text  w={'100%'}  noOfLines={1}>{jogo.casa1}</Text>
                                            {jogo.casa2!=null?<Text    w={'100%'} noOfLines={1}>{jogo.casa2}</Text> :null}  
                                        </Stack>
                                    </Stack> 
                                    <Stack  w={'50%'} align={'flex-end'} justify={'flex-end'} >
                                        <AvatarGroup size='md' max={2} mr='10px' mt='10px' >
                                            <Avatar  name={jogo.fora1} src={'https://demo.beachsports.bet/p/'+jogo.img03} />
                                            {jogo.img04!=null?  <Avatar   name={jogo.fora2} src={'https://demo.beachsports.bet/p/'+jogo.img04}/> :null} 
                                        </AvatarGroup>
                                        <Stack gap={0}  p={'10px'}   w={'100%'}  fontSize={{base:'10px',sm:'14px'}}   color={'tema.cor001'} align={'flex-end'} justify={'flex-end'} textAlign={'right'}>
                                            <Text  w={'100%'}  noOfLines={1}>{jogo.fora1}</Text>
                                            {jogo.fora2!=null?<Text    w={'100%'} noOfLines={1}>{jogo.fora2}</Text> :null}  
                                        </Stack>
                                    </Stack>  
                                </Flex> 
                                
                            </Stack>
                        </Stack>:<Skeleton opacity={'0.3'} w='100%'  h={'200px'}></Skeleton>}
                        
                    </Flex>
                    <Flex  p='0px' m={'0px'} gap={'10px'} >
                        <Stack w={'100%'}>
                            {Object.values(bets).map((m)=>{
                                return <Stack key={'tipo'+m.tipo} p={'5px 15px 15px 15px'} bg={'tema.bg002'}  borderRadius={'lg'}>
                                    <Text color={'tema.cor001'} w={'100%'} textAlign={'left'}>{m.nome}</Text>
                                    <BoxBetODDs oddTs ={m.dados} tipo = {m.tipo} />
                                </Stack>
                            })}
                        </Stack>
                       
                        {new Date(jogo.data).getTime()<srvTime?
                            <Stack  minW={{base:'300px'}}  gap={'5px'} >
                                <PlayAoVivoView  />
                            </Stack>
                        :null}
                    </Flex> 
                </> 
                } 
            </Stack> 
            }
        </>

    )
}
export default DetalheJogo;