import { Flex } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {Helmet} from 'react-helmet'  
import Menu from "../../menu/Menu";
import { cfg } from "../../dados/dados"; 
import {user,conectarServer,desconectarServer} from "../../conn/WebPainel";
import {conectarServer as conectarServerGame,desconectarServer as desconectarServerGame} from "../../conn/WebGame";
// pages
import Pages from "../../pages/Site/";

export const PageSite = [
    {  nome  :   "Esportes",    slug   :   ""        ,page: 'PageEsporte'},
    {  nome  :   "Esportes",    slug   :   "sport"   ,page: 'PageEsporte'}
];
const LayoutSite=(props)=>{
    const base =useRef();
    const [web,setWeb] = useState(null); 
    const [reload,setReload] = useState(null);  
    if(web == null){
        setWeb(true);
        conectarServer();
        conectarServerGame()
    }  
    useEffect(()=>{
        var interV = setInterval(()=>{ 
            if(user!==null && reload!==user.dataUP){ 
                setReload(user.dataUP);
            } 
        },100) 
        return ()=>{ 
            clearInterval(interV);
            desconectarServer();
            desconectarServerGame();
        }
    },[])   
    let pag =PageSite[0]; 
    PageSite.forEach((p)=>{
        if(props.page[1] == p.slug){
            pag =p;
        }
    })  
    const ViewPage = Pages[pag.page]; 
    
    let contentPage = (
        <Flex ref={base} direction={'column'}  bg={cfg.theme.padrao.bg1} fontFamily={cfg.theme.padrao.font} h={'100vh'} pt={ {base:'25px',md:'35px', lg:'60px'}} overflowX={'hidden'} overflowY={'auto'}>
            <Menu/>
            <ViewPage page={props.page}/>
        </Flex>
    )
    return (
        <>
            <Helmet> 
                <title>{cfg.nome} - {pag.nome}</title>
                <meta name="title" content={cfg.nome} />
                <meta name="description" content={cfg.descricao} />

                <link rel="icon" href={'/assets/'+cfg.site+'/icon.ico'} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={"https://"+cfg.site} />
                <meta property="og:title" content={"https://"+cfg.nome} />
                <meta property="og:description" content={cfg.descricao} />
                <meta property="og:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={"https://"+cfg.site} />
                <meta property="twitter:title" content={cfg.site} />
                <meta property="twitter:description" content={cfg.descricao} />
                <meta property="twitter:image" content={"https://"+cfg.site+"/assets/img/logoBanner.png"} />
            </Helmet>  
            {contentPage}
        </>
    )
    
}
export default  LayoutSite;