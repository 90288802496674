import { Box, Flex, Icon, Spacer, Spinner, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import QuadraBeachTennis from "./QuadraBeachTennis";
import { RiBarChart2Fill } from "react-icons/ri";

import { FiMonitor } from "react-icons/fi";
import { TbSoccerField } from "react-icons/tb";
import { hexToRGB } from "../../../Function";
import { cfg } from "../../../dados/dados";
import { GM } from "../../../conn/WebGame";
import { useEffect, useState } from "react";
import GradientBorder from "../../VisionUI/GradientBorder";
const PontoBox = function(props){
    const {game} = props; 
    const ac = {
        '1#C':{t:'36%',l:'4.5%',     w:12,h:12,  a:"animate__animated animate__heartBeat animate__infinite"},
        '1#V':{t:'36%',l:'95.5%',    w:12,h:12,  a:"animate__animated animate__heartBeat animate__infinite"},

        '2#C':{t:'64%',l:'4.5%',     w:12,h:12,  a:"animate__animated animate__heartBeat animate__infinite"},
        '2#V':{t:'64%',l:'95.5%',    w:12,h:12,  a:"animate__animated animate__heartBeat animate__infinite"},

        '9#C':{t:'36%',l:'20%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '9#V':{t:'36%',l:'80%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '10#C':{t:'64%',l:'20%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '10#V':{t:'64%',l:'80%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '11#C':{t:'36%',l:'40%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '11#V':{t:'36%',l:'60%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},
        
        '12#C':{t:'64%',l:'40%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '12#V':{t:'64%',l:'60%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '13#C':{t:'36%',l:'48%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '13#V':{t:'36%',l:'52%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '14#C':{t:'64%',l:'48%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '14#V':{t:'64%',l:'52%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '15#C':{t:'10%',l:'85%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '15#V':{t:'10%',l:'15%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '16#C':{t:'10%',l:'65%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '16#V':{t:'10%',l:'35%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '17#C':{t:'36%',l:'95.5%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '17#V':{t:'36%',l:'4.5%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},
        
        '18#C':{t:'64%',l:'95.5%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '18#V':{t:'64%',l:'4.5%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '19#C':{t:'90%',l:'85%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '19#V':{t:'90%',l:'15%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},

        '20#C':{t:'90%',l:'65%',     w:12,h:12,  a:"animate__animated animate__tada"},
        '20#V':{t:'90%',l:'35%',     w:12,h:12,  a:"animate__animated animate__heartBeat"},
        
        
    }
    let pos = '';
    if(game.acao.length==0){
        pos =  game.saque.tipo+'#'+game.saque.time;
    }else{
        pos =  game.acao[game.acao.length-1].tipo+'#'+game.acao[game.acao.length-1].time;
    } 
    const p =typeof ac[pos] !== 'undefined'?ac[pos]:{};
    return (
        <Flex zIndex={5} position={'absolute'} left={'0'} top={'0'} w={'full'} h={'100%'}   >
            <Box position={'absolute'} left={p.l} top={p.t}   w={p.w+'px'} h={p.h+'px'} ml={('-'+p.w/2)+'px'} mt={'-'+(p.h/2)+'px'} borderRadius={'100%'} bg={'yellow'} className={p.a} transition={'0.5s'} ></Box>
        </Flex>
    )
          
}
const MsgBox = function(props){ 
    return (
        <Flex zIndex={5} position={'absolute'} left={'0'} top={'0'} w={'full'} h={'100%'}  bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.5)' }  backdropFilter='blur(10px) hue-rotate(90deg)'>
            
            <Stack w={'full'} h={'full'} align={'center'}  justify={'center'}>
                <GradientBorder   borderRadius='5px' p='3px'>
                    <Box bg={'tema.bg001'}   borderRadius='5px' color={'tema.cor001'} p={'5px 10px'} >
                        {props.texto}
                        {props.sub} 
                    </Box>
                </GradientBorder> 
            </Stack>
        </Flex>
    )
}
const PlacarGame = function(props){  
    const  num= {
        0:'0',
        1:'15',
        2:'30',
        3:'40',
        4:'G',
    }
    return (
        <>
            <Flex zIndex={5} position={'absolute'} left={'0'} top={'0'} w={'full'} h={'100%'}  bg={'rgba('+hexToRGB(cfg.theme.cores.bg001)+',0.5)' }  backdropFilter='blur(10px) hue-rotate(90deg)' className={(props.show?'animate__animated animate__fadeInDown':'animate__animated animate__fadeOutDown animate__delay-1s')}></Flex>
            <Stack zIndex={6} position={'absolute'} w={'full'} h={'full'} align={'center'}  justify={'center'}>
                <GradientBorder   borderRadius='5px' p='3px' className={(props.show?'animate__animated animate__fadeInUp animate__delay-1s':'animate__animated animate__fadeOutUp')}>
                    <Flex bg={'tema.bg001'} direction={'column'} gap={'0px'}  borderRadius='5px' color={'tema.cor001'} p={'5px 10px'} >
                        <Stack>
                           <Text>SET {props.set}</Text> 
                        </Stack> 
                        <Flex bg={'tema.bg001'} gap={'20px'}  borderRadius='5px' color={'tema.cor001'} p={'5px 10px'} >
                            <Stack>
                            <Text>{(props.tipo>1)?props.placar.c:num[props.placar.c]}</Text> 
                            </Stack>
                            <Stack>
                                <Text>{(props.tipo>1)?props.placar.v:num[props.placar.v]}</Text> 
                            </Stack>
                        </Flex>
                        
                    </Flex>
                </GradientBorder> 
            </Stack>
        </>
    )
}
const PlayAoVivoView = function(){ 
    if(typeof GM.playAoVivo == 'undefined'){
        GM.playAoVivo={up:0,dados:{}}; 
    }    
     
    const [reload,setReload]= useState(GM.playAoVivo.up)
    useEffect(()=>{   
        var interV = setInterval(()=>{
            if(reload!=GM.playAoVivo.up){
                setReload(GM.playAoVivo.up); 
            }  
        },100) 
        return ()=>{
            clearInterval(interV); 
        } 
    },[reload]);
    let game = {};
    if(Object.keys(GM.playAoVivo.dados).length>0){    
        game = GM.playAoVivo.dados;    
    } 
    
    const jogo = game.jogo;  
    var fase = 0;
    let viewPlay = {
        info:null, 
    }
    if(game.dataI!=null){
        fase = 1;
    }
    const  num= {
        0:'0',
        1:'15',
        2:'30',
        3:'40',
        4:'G',
    }
    var tempos = [];
    switch (fase) {
        case 0: 
            viewPlay.table = (
                <TabPanel p={'0px'}  minH={'full'} position={'relative'}> 
                    <MsgBox 
                        texto={<Text fontSize={'14px'} color={'tema.cor001'} pr='18px'><Spinner color='tema.cor003' size={'xs'} mr={'10px'}/>Inciar em Breve</Text>} 
                        sub={<Text fontSize={'10px'} color={'tema.cor002'}> Jogadores em preparação</Text>} 
                    /> 
                    <QuadraBeachTennis  />
                </TabPanel>
            )
        break;
        case 1: 
            for(var t = 1; t<4;t++){ 
                if(t <= game.set){
                    tempos.push(
                        <Flex key={'placar'+t} direction={'column'} h={'100%'} w={'12px'} >
                            <Flex  w={'100%'} h={'20px'} align={'center'} justify={'center'} borderBottom={'2px solid'} borderColor={'tema.bg003'}>
                                <Text fontSize={'10px'} pt={'2px'}  lineHeight={'16px'} opacity={'0.5'}>{t}</Text><Spacer/>
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'left'} justify={'center'} textAlign={'left'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'} >
                                <Text opacity={'0.8'} w={'100%'} pt={'2px'} fontSize={'12px'} textTransform={'uppercase'} >{game.sets['set'+t].c}</Text>
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'left'} justify={'center'} textAlign={'left'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'}>
                                <Text opacity={'0.8'} w={'100%'} pt={'2px'} fontSize={'12px'}  textTransform={'uppercase'}   >{game.sets['set'+t].v}</Text>
                            </Flex>
                        </Flex>
                    )
                }
                
            }
                viewPlay.info = (
                    <Stack direction={'row'} w={'full'} h={{base:'62px'}} gap={'0px'} bg={'tema.bg001'} align={'center'} justify={'center'} color={'tema.cor001'} >
                        <Flex direction={'column'} flex={'1'}>
                            <Flex  w={'100%'} h={'21px'} align={'center'} justify={'center'} borderBottom={'2px solid'} borderColor={'tema.bg003'}>
                                <Text w={'100%'} px={'10px'} fontSize={'10px'} pt={'4px'}  lineHeight={'16px'} opacity={'0.5'}  textAlign={'left'}>Melhor de 3</Text>
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'center'}   justify={'center'} textAlign={'left'}>
                                <Text  w={'100%'} px={'10px'}  fontSize={'10px'} lineHeight={'22px'} textTransform={'uppercase'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'} noOfLines={1}>{jogo.jg01}</Text>
                                {jogo.jg02!=null? <Text  w={'100%'} px={'10px'}  lineHeight={'22px'} fontSize={'10px'} textTransform={'uppercase'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'} noOfLines={1}>{jogo.jg02}</Text>:null }
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'center'} justify={'center'} textAlign={'left'}>
                                <Text  w={'100%'} px={'10px'}  lineHeight={'22px'} fontSize={'10px'} textTransform={'uppercase'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'} noOfLines={1}>{jogo.jg03}</Text>
                                {jogo.jg04!=null? <Text  w={'100%'} px={'10px'} lineHeight={'22px'}  fontSize={'10px'} textTransform={'uppercase'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'} noOfLines={1}>{jogo.jg04}</Text>:null }
                            </Flex>  
                        </Flex> 
                        {tempos}
                         
                        <Flex direction={'column'} h={'100%'} borderLeft={'2px solid'} borderColor={'tema.bg003'}>
                            <Flex  w={'100%'} h={'20px'} align={'center'} justify={'center'} borderBottom={'2px solid'} borderColor={'tema.bg003'}>
                                <Text px={'5px'} fontSize={'10px'} pt={'2px'}  lineHeight={'16px'} opacity={'0.7'}>GAME</Text><Spacer/>
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'left'} justify={'center'} textAlign={'left'}>
                                    <Text  w={'100%'}  textAlign={'center'} pt={'2px'} fontSize={'12px'} textTransform={'uppercase'} bg={'rgba('+hexToRGB(cfg.theme.cores.cor003)+',0.3)'} >{(game.tipo>1)?game.placar.c:num[game.placar.c]}</Text>
                            </Flex>
                            <Flex w={'100%'} h={'22px'} align={'left'} justify={'center'} textAlign={'left'}>
                                <Text  w={'100%'} textAlign={'center'}  pt={'2px'} fontSize={'12px'}  textTransform={'uppercase'}  bg={'rgba('+hexToRGB(cfg.theme.cores.cor002)+',0.3)'} >{(game.tipo>1)?game.placar.v:num[game.placar.v]}</Text>
                            </Flex>
                        </Flex>
                    </Stack>
                )
            viewPlay.table = (
                <TabPanel p={'0px'}  minH={'full'} position={'relative'}> 
                    <PlacarGame 
                        show={game.saque== null?true:false} 
                        placar={game.placar}
                        tipo={game.tipo}
                        set={game.set}
                    />
                    {game.saque== null?
                        null :
                        <PontoBox game={game}/>
                    } 
                    <QuadraBeachTennis  />
                </TabPanel>
            )
        break;
    } 
    
    
    return (
        <Box w='100%' borderRadius='10px'  bg='tema.bg002' p={'10px'} >
            <Stack direction={'row'}  flex='1' h='40px' pb={'10px'} px={'10px'}  color={'tema.cor001'} align={'center'} justify={'center'}  textAlign='left'>
                <Text  >JOGO AO VIVO</Text><Spacer/>
                <Icon as={FiMonitor}></Icon>
                <Icon as={TbSoccerField}></Icon>
            </Stack>
            {viewPlay.info }
            <Tabs>
                <Stack flex={1} h={'max-content'}   w={'full'}> 
                    <TabPanels >
                        {viewPlay.table}
                        {/* <TabPanel  p={'0px'}   h={'186.66px'} > 

                        </TabPanel>
                        <TabPanel p={'0px'}  h={'186.66px'} >
                        <p>three!</p>
                        </TabPanel> */}
                    </TabPanels>
                </Stack>
                <Stack w={'full'} h={{base:'40px'}} bg={'tema.bg001'} align={'center'} justify={'center'}  color={'tema.cor001'} >
                    <TabList borderBottom={'0px'}>
                        <Tab>
                            <Icon as={TbSoccerField} fontSize={'26px'}/>
                        </Tab>
                        <Tab>
                            <Icon as={RiBarChart2Fill} fontSize={'22px'}/>
                        </Tab>
                        {/* <Tab>Three</Tab> */}
                    </TabList>  
                </Stack> 
            </Tabs>
        </Box>
    )
};
export default PlayAoVivoView;