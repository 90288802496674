import { Box, Button, Flex, Icon, InputGroup, InputLeftElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Skeleton, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Spacer, Stack, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { FaGlobe, FaMailBulk, FaRecycle, FaTicketAlt, FaTrashAlt } from "react-icons/fa";
import { hexToRGB } from "../../Function";
import { cfg } from "../../dados/dados";
import { LuMaximize2 } from "react-icons/lu";
import { addBet, betD, openB,setOpenB } from "../../hooks/apostaDados";
import InputCampo from "../Input/modelos/InputCampo";
import { GP, saldos, sendForm } from "../../conn/WebPainel";
import { NavLink } from "react-router-dom";
import BoxWindow from "../BoxWindow/BoxWindow";

const BoletimAposta = function(jogo){
        
        const [alerta,setAlerta] = useState({});   
        const [reload,setReload] = useState(null);
        const [tempO,setTempO] = useState(betD.up); 
        const [tempB,setTempB] = useState(openB);
        const [tempU,setTempU] = useState(0);
        const [sendV,setSendV] = useState(0);
        
        useEffect(()=>{ 
                var up = 0;
                var interV =setInterval(()=>{ 
                        if(openB!=tempB){
                                setTempB(openB)
                                setReload({});
                        }else if(betD.up!=tempO){
                                setTempO(betD.up)
                                setReload({});
                        }else if(typeof GP.odds !=='undefined'){
                                up =0;
                                betD.dados.forEach((ds=>{
                                        try {
                                                up+=GP.odds.dados[ds.d.i].up  
                                        } catch (error) {
                                                
                                        }
                                            
                                }))
                                if(up!=tempU){
                                        setTempU(up) 
                                        console.log(tempU);
                                        setReload({});
                                } 
                        }
                },100);
                return ()=>{
                        clearInterval(interV);
                } 
        },[openB,reload])   
        var jogo  = {},d={},link='',tipo=1;
        var odds = [];
        var mult = 1;
        betD.dados.forEach((ds=>{
                try {
                        odds.push(GP.odds.dados[ds.d.i].valor) 
                        mult*=GP.odds.dados[ds.d.i].valor;
                } catch (error) {
                        odds.push(0) 
                }
                    
        })) 
        return  <Stack gap={0} p='0px' maxH={{base:'calc(100vh - 60px)',lg:'calc(100vh - 80px)'}} >
                        <Flex w={'100%'} zIndex={'100000'}   bg={'tema.cor002'} p={'5px'} gap={'5px'}   borderRadius={'lg'}  boxShadow={'dark-lg'} align={'center'} justify={'space-between'} px={'10px'}> 
                                <Text as='h3'  fontWeight={'bold'} color={'tema.cor001'} fontFamily={'Barlow'} textTransform={'uppercase'} >Boletim</Text> 
                                <Button onClick={()=>{
                                        if(openB){
                                                setOpenB(false);
                                        }else{
                                                setOpenB(true);
                                        }
                                }} display={{base:'flex',xl:((openB)?'flex':'none')}} w={'24px'} h={'24px'} fontSize={'14px'}  minW={'auto'}  my={'5px'} colorScheme="blackAlpha" > 
                                <Icon as={LuMaximize2} />
                                </Button>
                        </Flex>
                        {betD.dados.length>0?
                                <Stack w={'100%'}  bg={'tema.bg002'} p={'10px 20px'}  borderEndRadius={'lg'}  display={{base:((openB)?'flex':'none'),xl:'flex'}}  overflowY={'auto'} >
                                        <Flex color={'tema.cor001'} gap={'20px'} fontWeight={'bold'}>
                                                <Text w={'50%'} lineHeight={'30px'}  borderBottom={betD.dados.length==1?'2px solid':''} borderColor={'tema.cor002'} color={betD.dados.length==1?'tema.cor002':''}>Simples</Text>
                                                <Text w={'50%'} lineHeight={'30px'}  borderBottom={betD.dados.length>1?'2px solid':''} borderColor={'tema.cor002'}  color={betD.dados.length>1?'tema.cor002':''}>Múltipla</Text> 
                                        </Flex>
                                        {betD.dados.length>0?
                                                <Stack>
                                                        { 
                                                        betD.dados.map((b,i)=>{
                                                                const {jogo,d,link,tipo} = b; 
                                                                return (
                                                                        <Stack key={d.i} direction={'column'} >
                                                                                <Stack bg={'tema.bg003'} gap='0'  p={'10px'} borderRadius={'10px'} >
                                                                                        <Flex w={'100%'}color={'gray.100'}  gap='10px'>
                                                                                                <Stack    align={'center'}  justify={'center'} gap='2px'>
                                                                                                        <Flex gap={'5px'} align={'flex-start'} lineHeight={'10px'} justify={'flex-start'} fontSize={'10px'} textAlign={'left'}>
                                                                                                                <Text maxW={'calc(100% - 5px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa1}</Text>
                                                                                                                <Text w={'10px'} textTransform={'uppercase'} noOfLines={'1'}>e</Text>
                                                                                                                <Text maxW={'calc(100% - 5px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.casa2}</Text>
                                                                                                        </Flex>
                                                                                                        <Flex  gap={'5px'} align={'flex-start'} justify={'flex-start'} fontSize={'10px'} textAlign={'left'}>
                                                                                                                <Text maxW={'calc(100% - 5px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora1}</Text>
                                                                                                                <Text w={'10px'} textTransform={'uppercase'} noOfLines={'1'}>e</Text>
                                                                                                                <Text maxW={'calc(100% - 5px)'} textTransform={'uppercase'} noOfLines={'1'}>{jogo.fora2}</Text>
                                                                                                        </Flex>  
                                                                                                </Stack> 
                                                                                                <Flex gap={0}>
                                                                                                        <Button onClick={()=>{
                                                                                                                addBet(d,tipo,jogo)
                                                                                                        }} size={'sm'} p='5px' minW='auto'  h={'24px'} variant={'ghost'} colorScheme="red">
                                                                                                                <Icon as={FaTrashAlt} fontSize={'12px'} />
                                                                                                        </Button> 
                                                                                                        <NavLink to={link}>
                                                                                                                <Button size={'sm'} p='5px' minW='auto' h={'24px'} variant={'ghost'} colorScheme="orange">
                                                                                                                        <Icon as={FaGlobe} />
                                                                                                                </Button>
                                                                                                        </NavLink>
                                                                                                </Flex>
                                                                                                
                                                                                                
                                                                                        </Flex>
                                                                                        <Flex w={'100%'} bg={'tema.bg003'} fontSize={'14px'} color={'tema.cor002'} align={'center'}  justify={'space-between'}>
                                                                                                <Text fontSize={'12px'}>{d.m} - {d.p}</Text> 
                                                                                                {odds[i]>0?<Text color={'whatsapp.600'}>{odds[i]}</Text>:<Skeleton opacity={'0.3'} w='40px'  h={'21px'}></Skeleton>}
                                                                                        </Flex>
                                                                                </Stack> 
                                                                        </Stack>
                                                                )
                                                        }) 
                                                        }
                                                        <Button onClick={()=>{
                                                                betD.dados=[];
                                                                setSendV(0);
                                                        }} variant={'outline'} leftIcon={<FaTrashAlt fontSize={'12px'}/>} colorScheme="red"  gap={'10px'} size={'md'}>
                                                                LIMPAR 
                                                        </Button>
                                                        
                                                        <Flex gap={'10px'} bg={'tema.bg003'} p={'0px 5px'} borderRadius={'10px'} align={'center'} justify={'center'}>
                                                                <Stack   gap={'0px'} align={'center'} justify={'center'} p={'0px'} color={'tema.cor001'} ml='5px'>
                                                                        <Text lineHeight={'20px'} fontSize={'14px'} textTransform={'uppercase'}>Aposta</Text>  
                                                                </Stack>
                                                                <InputCampo 
                                                                        tipo='valor'
                                                                        w='50%' 
                                                                        size='md'
                                                                        name='valor'
                                                                        mb='0px'
                                                                        br='8px'
                                                                        placeholder="R$"
                                                                        value={sendV}
                                                                        onChange={(e)=>{
                                                                                if(e.target.value==''){
                                                                                        setSendV(0)
                                                                                }else{
                                                                                        setSendV(parseInt(e.target.value))
                                                                                }
                                                                                
                                                                        }}
                                                                        max={saldos.saldo}
                                                                        error={''}
                                                                /> 
                                                        
                                                        </Flex>
                                                        <Stack w='100%' gap={0}>
                                                                <Flex opacity={0.4} color={'tema.cor001'} w='100%' gap={'0px'} align={'center'} justify={'space-between'} p={'0px'}>
                                                                        <Text lineHeight={'14px'} fontSize={'10px'} textTransform={'uppercase'}>Cotações totais</Text> 
                                                                        <Text lineHeight={'14px'} fontSize={'10px'}>ODD {parseFloat(mult.toFixed(3))}</Text> 
                                                                </Flex>
                                                                <Flex opacity={0.4} color={'tema.cor001'} w='100%' gap={'0px'} align={'center'} justify={'space-between'} p={'0px'}>
                                                                        <Text lineHeight={'14px'} fontSize={'10px'} textTransform={'uppercase'}>Valor Apostado</Text> 
                                                                        <Text lineHeight={'14px'} fontSize={'10px'}>R$ {sendV}</Text> 
                                                                </Flex> 
                                                                
                                                                <Flex color={'whatsapp.400'} w='100%' gap={'0px'} align={'center'} justify={'space-between'} p={'0px'} fontSize={'12px'} >
                                                                        <Text lineHeight={'20px'} textTransform={'uppercase'}>Ganho Totais</Text> 
                                                                        <Text lineHeight={'20px'}>R$ {parseFloat((sendV*mult).toFixed(2))}</Text> 
                                                                </Flex>
                                                        </Stack>
                                                        <Button onClick={()=>{
                                                                setAlerta({load:true})   
                                                                var sendB = [];
                                                                betD.dados.forEach((b,i)=>{
                                                                    sendB.push({
                                                                        apo:b.d.i,
                                                                        mar:b.d.t,
                                                                        pos:b.d.cp,
                                                                        odd:odds[i],
                                                                        jog:b.jogo.id

                                                                    })    
                                                                })    
                                                                sendForm({ modulo:'bilhete',cmd:'register',form:{valor:sendV,bet:sendB},callback:(result)=>{  
                                                                        if(!result.erro){
                                                                                betD.dados=[];
                                                                                setSendV(0);
                                                                        }
                                                                        result.fechar =true;
                                                                        result.size = 'md';  
                                                                        setAlerta(result);
                                                                        
                                                                }}); 
                                                        }} variant={'outline'} leftIcon={<FaTicketAlt fontSize={'12px'}/>} colorScheme="whatsapp"  gap={'10px'} size={'md'}>
                                                                Fazer Aposta 
                                                        </Button>
                                                </Stack>
                                                :
                                                null
                                        }  
                                        
                                </Stack>
                        :
                        <Stack w={'100%'}  bg={'tema.bg002'} p={'20px'}  borderEndRadius={'lg'}  display={{base:((openB)?'flex':'none'),xl:'flex'}}  >
                                <Text color={'tema.cor001'}>Nenhuma Aposta</Text>
                        </Stack>
                        }
                <BoxWindow param ={alerta} />
        </Stack>
                
} 
export default BoletimAposta;