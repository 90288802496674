import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, Icon, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, SimpleGrid, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Stack, Step, StepDescription, StepIcon, StepIndicator, StepNumber, StepSeparator, StepStatus, StepTitle, Stepper, Text, Textarea, Tooltip, useSteps, useToast } from "@chakra-ui/react";
import { FaCheck, FaCheckDouble, FaCode, FaCopy, FaDollarSign, FaDollyFlatbed, FaHistory, FaListAlt, FaMoneyBill, FaQrcode, FaRegTimesCircle, FaReplyAll, FaRocket, FaRocketchat, FaTimes } from "react-icons/fa";


import { useEffect, useState } from "react"; 
import { SiTether } from "react-icons/si"; 
import QRCode from "react-qr-code"; 
import BoxWindow from "../../components/BoxWindow/BoxWindow";
import { GP,saldos,sendForm } from "../../conn/WebPainel"; 
const BoxDeposito =function (props) {
    const [alerta,setAlerta] = useState({});   
    const [reload,setReload] = useState(0);   
    
    const toast = useToast()
    const [sendV,setSendV] =  useState(0);
    let {sendD={},setSendD=()=>{},setLoad={}} = props;  
    let pedido = null; 
    if(typeof GP.deposito !== 'undefined'){ 
        pedido = Object.values(GP.deposito.dados);
    } 
    console.log('render',pedido)
    useEffect(()=>{
        var interV = setInterval(()=>{
            if(typeof GP.deposito !== 'undefined'){ 
                if(GP.deposito.up!=reload){ 
                    setReload(GP.deposito.up) 
                } 
            } 
        },100)
        return ()=>{
            clearInterval(interV);
        }
    },[alerta,sendV]); 

    let ViewBox = null;  
    if(pedido === null){ 
        ViewBox =    null 
    }else if(pedido.length>0){
        var d = pedido[0];  
        switch (d.codtip) {
            case 1:
                ViewBox = <> 
                    <Stack spacing={0} pt='10' align='center' justify='center' gap={'20px'}>  
                        <Box bg='tema1.100' w={{base:'100%'}}   borderRadius={'lg'}>
                            <Center p='20px' bg={'tema.cor002'}>
                                <QRCode value={d.carteira}  bgColor="transparent" size={240}  /> 
                            </Center> 
                        </Box>
                        <Stack align='center' justify={'center'} flexDir={'row'}  w={{base:'100%'}}> 
                            <Text  color='tema.cor002'>
                                Escanei a carteira BEP-20 via QRCODE ou via copiar e colar!
                            </Text>   
                        </Stack>
                        <Stack align='center' justify={'center'} flexDir={'row'} w={{base:'100%'}} gap='20px'>
                            <FormControl w='full'>
                                <FormLabel
                                    color='white' w='full'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Valor
                                </FormLabel>
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                {d.valor} USDT
                                </Text>
                            </FormControl>  
                            <FormControl>
                                <FormLabel
                                    color='white'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Expira Em:
                                </FormLabel> 
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                    {(d.expira!== null) ? new Date(d.expira).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}) : "Não definido"}
                                </Text>
                            </FormControl>
                        </Stack>
                        <Stack align='center' w={'100%'} justify={'center'} flexDir={'row'}  spacing={1}>  
                             
                            <Textarea id="copyText" color={'tema.cor002'} overflow='hidden' bg='tema.bg001'   border='0.5px solid' borderColor='tema.cor002' isReadOnly placeholder='Here is a sample placeholder' w={{base:'100%'}} resize='none' onClick={(e)=>{ 
                                e.preventDefault();   
                                var Url = document.getElementById("copyText");
                                Url.select() 
                                document.execCommand("copy");
                                toast({
                                    title: 'Cópia realizada com sucesso!',
                                    description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                    status: 'success',
                                    position:'top-right',
                                    duration: 9000,
                                    isClosable: true,
                                })      
                            }}
                            defaultValue={d.carteira}
                            /> 
                        </Stack>  
                        <Button onClick={(e)=>{
                             e.preventDefault();   
                             var Url = document.getElementById("copyText");
                             Url.select() 
                             document.execCommand("copy");
                             toast({
                                 title: 'Cópia realizada com sucesso!',
                                 description: "Você copiou a carteira BEP-20 na memória, agora só transferir o valor exato para essa carteira, e assim  finalizar o pagamento",
                                 status: 'success',
                                 position:'top-right',
                                 duration: 9000,
                                 isClosable: true,
                             })      
                        }} leftIcon={<FaCopy/>} variant={'outline'} colorScheme="cyan">Copiar Carteira</Button>
                        <Button onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Atenção',
                                    size:'md',
                                    mensagem:'Caso já tenha pago, o seu pacote não será ativado, deseja realmente cancelar esse pagamento?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'cyan',
                                            cmd:()=>{
                                                sendForm({modulo:'pedidos',cmd:'deletar',form:{id:d.id},callback:(result)=>{
                                                    if(result.erro){
                                                        result.fechar =true;
                                                        result.size = 'md';
                                                        setAlerta(result);
                                                    }else{ 
                                                        setAlerta({close:true});
                                                    }
                                                    
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} leftIcon={<FaTimes/>} variant={'ghost'} colorScheme="red">Cancelar Pagamento</Button>
                    </Stack> 
                </>;
            break; 
            case 2:
                ViewBox = <> 
                    <Stack spacing={0} pt='10' align='center' justify='center' gap={'20px'}>  
                        <Box bg='tema1.100' w={{base:'100%'}}   borderRadius={'lg'}>
                            <Center p='20px' bg={'tema.cor002'}>
                                <QRCode value={'00020101021126580014br.gov.bcb.pix01362ef08383-1c72-497a-b99d-12808848d4335204000053039865406100.005802BR5907SKYLIBS6009SAO PAULO622905251HY3R3C8QD3SGWPXRFGWHPN2F63040885'}  bgColor="transparent" size={240}  /> 
                            </Center> 
                        </Box>
                        <Stack align='center' justify={'center'} flexDir={'row'}  w={{base:'100%'}}> 
                            <Text  color='tema.cor002'>
                                Escanei  QRCODE ou via copiar e colar!
                            </Text>   
                        </Stack>
                        <Stack align='center' justify={'center'} flexDir={'row'} w={{base:'100%'}} gap='20px'>
                            <FormControl w='full'>
                                <FormLabel
                                    color='white' w='full'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Valor
                                </FormLabel>
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='Nome da conta'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                {d.valor} 
                                </Text>
                            </FormControl>  
                            <FormControl>
                                <FormLabel
                                    color='white'
                                    fontSize='xs'
                                    fontWeight='bold'
                                    mb='10px'
                                >
                                Expira Em:
                                </FormLabel> 
                                <Text
                                    color='#FFFFFF'
                                    bg='tema.bg001'
                                    border='0.5px solid'
                                    borderColor='tema.cor002'
                                    borderRadius='lg'
                                    placeholder='PIX copiar e colar'
                                    fontSize='xs' 
                                    textAlign={'left'}
                                    padding={'10px'}
                                >
                                    {(d.expira!== null) ? new Date(d.expira).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit',hour:'2-digit',minute:'2-digit'}) : "Não definido"}
                                </Text>
                            </FormControl>
                        </Stack>
                        <Stack align='center' w={'100%'} justify={'center'} flexDir={'row'}  spacing={1}>   
                            <Textarea id="copyText" color={'tema.cor002'} overflow='hidden' bg='tema.bg001'   border='0.5px solid' borderColor='tema.cor002' isReadOnly placeholder='PIX copiar e colar' w={{base:'100%'}} resize='none' onClick={(e)=>{ 
                                e.preventDefault();   
                                var Url = document.getElementById("copyText");
                                Url.select() 
                                document.execCommand("copy");
                                toast({
                                    title: 'Cópia realizada com sucesso!',
                                    description: "Você copiou o PIX na memória, agora só só fazer o pagamento.",
                                    status: 'success',
                                    position:'top-right',
                                    duration: 9000,
                                    isClosable: true,
                                })      
                            }}
                            defaultValue={'00020101021126580014br.gov.bcb.pix01362ef08383-1c72-497a-b99d-12808848d4335204000053039865406100.005802BR5907SKYLIBS6009SAO PAULO622905251HY3R3C8QD3SGWPXRFGWHPN2F63040885'}
                            /> 
                        </Stack>  
                        <Button onClick={(e)=>{
                             e.preventDefault();   
                             var Url = document.getElementById("copyText");
                             Url.select() 
                             document.execCommand("copy");
                             toast({
                                 title: 'Cópia realizada com sucesso!',
                                 description: "Você copiou o PIX na memória, agora só só fazer o pagamento.",
                                 status: 'success',
                                 position:'top-right',
                                 duration: 9000,
                                 isClosable: true,
                             })      
                        }} leftIcon={<FaCopy/>} variant={'outline'} colorScheme="cyan">Copiar PIX</Button>
                        <Button onClick={()=>{
                            setAlerta({load:true})
                            setTimeout(()=>{
                                setAlerta({
                                    fechar:true,
                                    titulo:'Atenção',
                                    size:'md',
                                    mensagem:'Caso já tenha pago, o seu pacote não será ativado, deseja realmente cancelar esse pagamento?', 
                                    botao:[
                                        {
                                            nome:'SIM',
                                            color:'cyan',
                                            cmd:()=>{
                                                sendForm({modulo:'pedidos',cmd:'deletar',form:{id:d.id},callback:(result)=>{
                                                    if(result.erro){
                                                        result.fechar =true;
                                                        result.size = 'md';
                                                        setAlerta(result);
                                                    }else{ 
                                                        setAlerta({close:true});
                                                    }
                                                    
                                                }});
                                                setAlerta({load:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            color:'red'
                                        }
                                    ]
                                });
                            })
                        }} leftIcon={<FaTimes/>} variant={'ghost'} colorScheme="red">Cancelar Pagamento</Button>
                    </Stack> 
                </>;
            break;
        
            default:
                break;
        } 
    }else{
        var valores = [10,50,100,500,1000, 5000,30000,50000]
        ViewBox = <>
            <Stack gap={'20px'}> /
                <SliderThumbWithTooltip  sendV={sendV} setSendV={(v)=>{setSendV(v)}} />  
                <SimpleGrid columns={4} spacing={'20px'}>
                    {valores.map((v,i)=>{  
                        return (
                            <Button key={'pos'+i} onClick={(r)=>{
                                setSendV(v)
                            }} w={'full'} size={'sm'} variant={'outline'} colorScheme="orange">
                                R$ {v}
                            </Button>
                        )
                    })} 
                </SimpleGrid>
                    
                <Button onClick={(e)=>{
                    e.preventDefault();   
                    setAlerta({load:true})   
                    sendForm({ modulo:'pedidos',cmd:'deposito',form:{valor:sendV,forma:2},callback:(result)=>{  
                        if(result.erro){
                            result.fechar =true;
                            result.size = 'md';  
                            setAlerta(result);
                        }else{  
                            setAlerta({close:true});  
                        }
                    }}); 
                }} mt={'10px'} leftIcon={<FaMoneyBill/>} w={'100%'} colorScheme="cyan" >
                    Fazer Pagamento
                </Button>
            </Stack>
        </>
    }
    
  
    return (
       <Stack>
            {ViewBox}
            <BoxWindow param ={alerta} />
       </Stack>
    )
} 
const SliderThumbWithTooltip =function (props) {
    let {sendV={},setSendV=()=>{},setLoad={}} = props;  
    const [sliderValue, setSliderValue] = useState(0)
    const [showTooltip, setShowTooltip] = useState(false); 
    return (
      <Box> 
        <Text color={'tema.cor002'} mb={'10px'}>Valor de depósito</Text>
        <NumberInput id='ipdeposito'  step={10}   value={`R$` +sendV} min={10} onChange={(v)=>{
            var num = parseInt(v);
            
            if(num>100000){
                num= 100000;
            }
            setSendV(num); 
             
        }} max={100000} variant={'outline'} color='tema.cor001' colorScheme="cyan">
            <NumberInputField />
            <NumberInputStepper>
                <NumberIncrementStepper  color="tema.cor002"/>
                <NumberDecrementStepper  color="tema.cor002"/>
            </NumberInputStepper>
        </NumberInput>
        <Slider
             focusThumbOnChange = {false}
            value={sliderValue}
            min={10}
            max={100000} 
            step={10}
            colorScheme='cyan'
            onChange={(v) => setSendV(v)} 
            mt={'5px'}
            w={'95%'} 
        >
            <SliderMark color={'tema.cor002'} value={25000} mt='1' ml='-2.5' fontSize='sm'>
            25K
            </SliderMark>
            <SliderMark color={'tema.cor002'}  value={50000} mt='1' ml='-2.5' fontSize='sm'>
            50K
            </SliderMark>
            <SliderMark color={'tema.cor002'}  value={75000} mt='1' ml='-2.5' fontSize='sm'>
            75K
            </SliderMark>
            <SliderTrack>
            <SliderFilledTrack />
            </SliderTrack> 
            <SliderThumb /> 
        </Slider> 
      </Box>
    )
}
// const PageDeposito = function(){
//     const [load,setLoad] = useState(0);
//     const [sendD,setSendD] = useState({
//         valor:'',
//         forma:'',
//     })
//     useEffect(()=>{ },[load]) 
//     const [reload,setReload]= useState(0);
//     const titulos = [  
//         {nome:'STATUS',     w:10,    campo:'nome'},  
//         {nome:'VALOR',      w:5,    campo:'valor'}, 
//         {nome:'DATA',       w:5,    campo:'criacao'} 
//     ]; 
//     let dados = null; 
//     useEffect(()=>{    
//         var interV = setInterval(()=>{
//             try { 
//                 if(reload!=(GP.pedidos.up + GP.deposito.up)){ 
//                     setReload((GP.pedidos.up + GP.deposito.up)); 
//                 } 
                
//             } catch (error) { }  
//         },100) 
//         return ()=>{
//             clearInterval(interV); 
//         } 
//     },[reload]);
    
//     let depositoP = 0; 
//     try { depositoP = Object.values(GP.deposito.dados)[0].valor_base;} catch (error) {depositoP = 0;}  
//     const situaInfo = {
//         "P": 'A Pagar ',
//         "N": 'Pago ',
//         "E": 'Expirado'
//     }
//     if(typeof GP.pedidos !== 'undefined'){
//         dados   = []; 
//         Object.values(GP.pedidos.dados).forEach((d)=>{
//             const {id,situa,codtip,valor,quantidade,moeda,criacao}=d;  
//             dados.push({
//                 campos  :   d,
//                 id      :   <Text  noOfLines={'1'}>{id}</Text>, 
//                 nome    :   <Text  noOfLines={'1'}>{situaInfo[situa]} via {codtip} {codtip==1?'CRYPTO':'PIX'}</Text>,  
//                 valor    :   <Text  noOfLines={'1'}>{valor*quantidade} {moeda}</Text>,  
//                 criacao: <Text  noOfLines={'1'} fontSize={{base:'10px',md:'12px',lg:'14px'}}>{new Date(criacao).toISOString().split('T')[0]}</Text>,
//             });
//         })
//     }   
//     return (
//         <Stack gap={'20px'}>
//             <SimpleGrid    columns={{base:2,'lg':4}} spacing={'20px'}>
//                 <CarBoxInfo titulo='Saldo' valor={saldos.saldo} icon={FaDollarSign}/>
//                 <CarBoxInfo titulo='Depósitos'  sub='Totais'    valor={(saldos.depositos+depositoP)} icon={FaCheckDouble}/>
//                 <CarBoxInfo titulo='Depósitos'  sub='Completo'  valor={saldos.depositos} icon={FaCheck}/>
//                 <CarBoxInfo titulo='Depósitos'  sub='Pendente' valor={depositoP} icon={FaHistory}/>
//             </SimpleGrid> 
//             <Flex gap={'20px'} direction={{base:'column',lg:'row'}}>
//                 <CardBox w={{base:'100%',lg:'320px'}}>
//                     <BoxDeposito  sendD={sendD} setSendD={setSendD} setLoad={setLoad}/>
//                 </CardBox>
//                 <CardBox w={{base:'100%'}}>
//                     <CardBoxTitulo position='absolute'>Últimos Depósitos</CardBoxTitulo>
//                     <ListaResponsiva titulos={titulos} dados={dados} max={5}  />
//                 </CardBox>
//             </Flex>
//         </Stack>
//     )
// };
export default BoxDeposito;