import Menus from ".";
import { cfg } from "../dados/dados";
const Menu=()=>{
    var page= null;
    try { 
        var MenuView = Menus[cfg.theme.menu.modelo]; 
        page= <MenuView />
    } catch (error) {
        
    } 
    return page;
}
export default Menu;