import { Button, Flex, Icon, Img, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react"; 
import Fn from "../../Function"; 
import { cfg } from "../../dados/dados";
import { NavLink, useLocation } from "react-router-dom";
import { IoMdArrowDropdown, IoMdMenu} from 'react-icons/io'
import UserBox from "../../components/User/UserBox";
import MenuGeral from "./MenuGeral";
const MenuTopo=(props)=>{
    const [fixo,setFixo] = useState(false);
    const [viewMenu,setViewMenu] = useState(false);
    Fn.updatePage =(scroll)=>{ 
        if(scroll>80){
            setFixo(true)
        }else{ 
            setFixo(false)
        }
    }
    useEffect(()=>{
        console.log("reload fixo")
    },[fixo]) 

    const location = useLocation();  
    const menu= cfg.theme.menu;
    const page= cfg.pages; 
    const url =(location.pathname.toUpperCase()+'/').replace('//','/').split('/')[1];     
    return <Flex zIndex={100} position={'absolute'} bg={{base:'tema.bg001',lg:!fixo?'tema.bg001':'tema.bg001'}} transition={'2s'}  direction={'row'} h={{base:'50px',md:'60px', lg:fixo?menu.scroll.h+'px' :menu.h+'px'}} top={{base:'0px',lg: !fixo?menu.t+'px':0}} align={'center'}  justify={'center'} w={'100%'}  >
        <Flex w={'100%'}   maxW={cfg.theme.maxW}  direction={'row'} align={'center'}  justify={{base:'flex-start',md:'space-between'}} px={{base:'0px',lg:'0px'}}>
            <Stack  w={{base:'auto'}}  direction={'row'} h='40px'  align={'center'}  spacing={{base:'0px'}} >
                {page.map((pg, id)=>{ 
                    if(!pg.ocultar){
                        var ativo =false; 
                        if(url==pg.url.replace(/\//g,"").toUpperCase()){
                            ativo =true;
                        }  
                        if(typeof pg.sub !='undefined'){
                            return <Flex key={'menu'+id} display={{base: 'none', lg:'flex'}}  position={'relative'} zIndex={'10'}>
                                    <Menu> 
                                        <MenuButton as={Button}  rightIcon={<IoMdArrowDropdown/>} fontSize={{base:'sm',md:'md'}} px='15px' bg={menu.bt.bg} color={menu.bt.clr}  _hover={{bg:menu.bt.bgH,color:menu.bt.clrH}}  _active={{bg:menu.bt.bgH,color:menu.bt.clrH,borderBottom:menu.bt.bbH}}>
                                            {pg.nome}
                                        </MenuButton>
                                        <MenuList bg={menu.boxSub.bg}>
                                            {pg.sub.map((sub,sid)=>{
                                                return <NavLink key={'menu'+id+'sub'+sid} to={pg.url+sub.url}>
                                                        <MenuItem bg={menu.boxSub.bt.bg} color={menu.boxSub.bt.clr}  _hover={{bg:menu.boxSub.bt.bgH,color:menu.boxSub.bt.clrH}}>{sub.nome}</MenuItem>
                                                    </NavLink>
                                            })} 
                                        </MenuList>
                                    </Menu> 
                                </Flex>
                        }else{
                            return <NavLink key={'menu'+id} to={pg.url}>
                                <Button   display={{base: 'none', lg:'flex'}}  fontSize={{base:'sm',md:'md'}} px='15px' bg={menu.bt.bg}   color={ativo?'tema.cor002':'tema.cor001'} _hover={{bg:menu.bt.bgH,color: 'tema.cor003'}} >{pg.nome}</Button>
                            </NavLink>
                        } 
                    } 
                })} 
                <Button display={{base:'flex',lg:'none'}} size={'lg'}  onClick={()=>{
                    if(viewMenu){
                        setViewMenu(false);
                    }else{
                        setViewMenu(true);
                    }
                    
                }}  colorScheme="orange"  variant={'ghost'} fontSize={'3xl'} p={'10px'} ml={'0px'}>
                    <Icon as={IoMdMenu} /> 
                </Button>
            </Stack> 
            <Stack w={{base:'auto'}} align={'center'} justify={'center'}>
                <NavLink to={'/'}>
                    <Img src={"/assets/"+cfg.site+"/logo_menu.webp"}  h={{base:'30px', md:'40px'}}/>
                </NavLink>
            </Stack>
            <Spacer display={{base:'flex',md:'none'}}/>
            <Stack  w={{base:'auto'}}  direction={'row'} h='40px'  align={'center'}    justify={'flex-end'}  spacing={{base:'0px',sm:'10px'}} >
                <UserBox />
            </Stack>
            
            <Stack display={{base:viewMenu?'flex':'none',lg:'none'}} top={{base:'50px',md:'60px', lg:'80px'}} position={'fixed'} w={{base:'230px'}} h={ {base:'calc(100vh - 50px)',md:'calc(100vh - 60px)', lg:'calc(100vh - 80px)'}}  left={'0px'} overflowY={'auto'} gap={'20px'} >
                <MenuGeral/> 
            </Stack>

        </Flex> 
    </Flex>
}
export default  MenuTopo;