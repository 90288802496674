import { useEffect, useState } from "react";
import {cfg} from "./dados/dados";
import { srvTime } from "./conn/WebPainel";
let Fn={
    objScroll: [],
    interV:null,
    updatePage:null,
    scrollCMD: function(event){  
        var self =this;
        let ponts = [];
        try {
            document.querySelectorAll('.boxScroll').forEach((el)=>{  
                if(el.getBoundingClientRect().y+(el.getBoundingClientRect().height*0.5)>=0){
                    ponts.push(
                        {
                            el  :   el,
                            ord :   el.getBoundingClientRect().y+(el.getBoundingClientRect().height*0.7),
                            pos :   el.getBoundingClientRect().y+event.target.scrollTop
                        } 
                    )
                }
                
            });
            try { 
                Fn.updatePage(event.target.scrollTop);
            } catch (error) {
                
            }
            clearTimeout(this.interV);
            this.interV =setTimeout(()=>{ 
                try {
                    if(ponts[0].ord!==0){
                        if(cfg.theme.menu.scroll.ativo){
                            ponts[0].pos-=cfg.theme.menu.scroll.top;
                        }
                        event.target.scrollTo({top: ponts[0].pos, behavior: cfg.theme.menu.scroll.animation });
                    } 
                } catch (error) {
                    
                }
                 
            },100);
        } catch (error) {
            
        } 
    },
    pageScroll:function(obj){  
        obj.addEventListener("scroll",this.scrollCMD); 
    },
    disableScroll:function(obj){ 
        obj.removeEventListener('scroll',this.scrollCMD)
        
    }
}
export const parseValor=( num,decimal=2)=>{
    var numN = {
        p:0,
        d:0,
        f:0, 
        fv:0
    } 
    if(num>=0){  
        var valor = parseFloat(num).toFixed((decimal+2)).toString(); 
        valor     =   valor.substring(0, valor.length - 2);  
        var pos   =   parseFloat(valor).toString().split('.'); 
        numN.p    = pos[0].replace(/(.)(?=(\d{3})+$)/g,'$1.'); 
        numN.d    = (typeof pos[1] =='undefined'?0:pos[1]);
        numN.f    =  numN.p+','+numN.d 
        numN.fv    =  numN.p+'.'+numN.d 
        return numN;
    }else{
      return numN;
    }  
}
export const TempoCronometro = (props)=>{
    const [time,setTime] = useState(props.time)
    useEffect(()=>{
        let interV = setInterval(()=>{ 
            setTime(props.time - srvTime) 
        },1000);
        return ()=>{
            clearInterval(interV);
        }
    },[time,props.time]) 
    
    var msg = '';
    var d  = {
        d:0,
        h:0,
        m:0,
        s:0
    }
    var t = ((props.time - new Date().getTime())*0.001).toString().split('.')[0];
    if(props.view =='tempo'){
        if(t<0){
            t*=-1;
        }
        d.d = parseInt((t/86400).toString().split('.')[0]);
        var s=t%86400;
        d.h = parseInt((s/3600).toString().split('.')[0]);
        s=s%3600;
        d.m = parseInt((s/60).toString().split('.')[0]);
        d.s=s%60; 
        if(d.d>0){
            msg +=d.d>1?d.d+' dias - ':d.d+' dia - ';
            if(t>3600){
                msg +=d.h+'h ';
            }
        }else{
            if(t>3600){
                msg +=d.h+'h ';
            }
            if(t>60){
                msg +=d.m+'m ';
            }
            if(t>60){
                msg +=d.s+'s ';
            }
        }
        
        
    }
    return msg
}
export const hexToRGB = (hex_value) => {
    const numericValue = parseInt(hex_value.replace('#',''), 16);
    const r = numericValue >> 16 & 0xFF;
    const g = numericValue >> 8 & 0xFF;
    const b = numericValue & 0xFF;
    return `${r}, ${g}, ${b}`
}
export default Fn;