import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Icon, Stack, Text } from "@chakra-ui/react";
import { FaGlobe } from "react-icons/fa"; 
const MenuLigas = function(){
    return (
        <Box w='100%' borderRadius='10px'  bg='tema.bg002' >
            <Accordion allowMultiple  >
                <AccordionItem border={'0'} color={'tema.cor001'}  p={'0px'}  >
                    <h2 >
                    <AccordionButton  px={'10px'} > 
                        <Box as="span" flex='1' p={'5px 10px'} textAlign='left'>
                        PRINCIPAIS LIGAS
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel p={'6px'} pb={'8px'}>
                         <Stack borderRadius={'lg'} overflow={'hidden'} gap={'2px'}>
                            <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bgh003'}}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 1
                                </Box> 
                            </AccordionButton> 
                            <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bgh003'}}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 2
                                </Box> 
                            </AccordionButton> 
                            <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bgh003'}}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 3
                                </Box> 
                            </AccordionButton> 
                         </Stack>
                        
                    </AccordionPanel>
                </AccordionItem>

                
            </Accordion> 
        </Box>
    )
}
export default MenuLigas;