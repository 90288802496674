import { Box, Center, Flex, Image, Img, Spacer, Stack, Text, color } from "@chakra-ui/react";
import { cfg } from "../../../dados/dados";
import { Bt1 } from "../../Button/ButtonView";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { hexToRGB } from "../../../Function";
const Slide001 = (props)=>{
    const {dados}=props; 
    const [ps,setPs] = useState(0);
    const [bar,setBar] = useState(0);
    useEffect(()=>{
        if(bar==0){
            setTimeout(()=>{ 
                setBar(100)
            });
        }  
    },[bar])
    useEffect(()=>{
        setBar(0);
        
        setTimeout(()=>{
            proximoSlide();
        },slide[ps].time);
        var sOb = boxSlide.current.querySelectorAll('.boxHomeSlide'); 
        sOb.forEach(element => {
            element.style.opacity = 0.2;
        });
        sOb[ps].style.opacity = 1;  
        var center = window.innerWidth*0.5 - sOb[ps].offsetWidth/2 -5
        boxScroll.current.scrollBy({
            left: sOb[ps].offsetLeft-boxScroll.current.scrollLeft-center ,
            top: 0,
            behavior: 'smooth'
        }) 
    },[ps]); 

    const slide =[ 
        { 
            titulo      :   'BÔNUS DE BOAS-VINDAS',
            descricao   :   "Ganhe até R$500 em bônus na sua primeira recarga!",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Fazer Recarga",
            img         :   'slideHome0001.png',
            cor:{
                bg:'#3FB803',
                btbg1:'#000000',
                btbg2:'#222222',
                btclr1:'#ffffff', 
                btclr2:'#ffffff',
                
            }
        },
        { 
            titulo      :   'RODADA DA SORTE',
            descricao   :   "Apostas grátis, rodadas grátis e fichas douradas todos os dias!",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Ver Agora",
            img         :   'slideHome0002.png',
            cor:{
                bg:'#2B1512',
                btbg1:'#000000',
                btbg2:'#222222',
                btclr1:'#ffffff', 
                btclr2:'#ffffff',
                
            }
        },
        { 
            titulo      :   'BEACH TENNIS É AQUI',
            descricao   :   "Acompanhe os principais torneios de beach tennis ao vivo!",
            time        :   8000,
            link        :   "/servicos/website-modelos/",
            linkTitulo  :   "Ver Agora",
            img         :   'slideHome0003.png',
            cor:{
                bg:'#11358B',
                btbg1:'#000000',
                btbg2:'#222222',
                btclr1:'#ffffff', 
                btclr2:'#ffffff',
                
            }
        } 
    ];
    let boxSlide    =  useRef();
    let boxScroll   =  useRef(); 
 

    function proximoSlide(){
        if(typeof slide[ps+1] == 'undefined'){
            setPs(0);
        }else{
            setPs(ps+1);
        }
    } 
    function voltarSlide(){
        if(typeof slide[ps-1] == 'undefined'){
            setPs(slide.length-1);
        }else{
            setPs(ps-1);
        }
    } 
 
    return (
        <Flex  h={ {base:'430px', '2xl':'580px'}}  direction={"column"} pt={{base:'0px'}} w={'100%'} bg={'tema.bg001'} pos={'relative'} className="boxScroll" align={'center'} justify={'center'}>
            <Box ref={boxScroll} w={'100%'} overflowX={'hidden'} h={{base:'380px', '2xl':'480px'}}  px={{base:'100vw'}} transition={'3s'}>
                <Flex ref={boxSlide} w={'max-content'}  direction={'row'}  gap={'20px'} >
                    {
                        slide.map((s,id)=>{  
                            var cor = typeof s.cor !=='undefined'?s.cor.bg:'#EA6925';
                            return (
                                <Box className="boxHomeSlide" maxW={'6xl'} key={'slideHome'+id}  position={'relative'} w={'calc(100vw - 80px)'}  h={{base:'280px', '2xl':'380px'}} bg={cor} borderRadius={'2xl'} overflow={'hidden'}>
                                    <Stack position={'relative'} zIndex={2} h={'100%'} w={'80%'} p='30px' justify={'left'} textAlign={'left'} bg={"linear-gradient(90deg, rgb("+hexToRGB(cor)+") 0px, rgb("+hexToRGB(cor)+") calc(100% - 12em), rgba("+hexToRGB(cor)+", 0.4) calc(100% - 6em), rgba("+hexToRGB(cor)+", 0) 100%)"}>
                                        <Text fontSize={{base:'2xl',lg:'4xl','2xl':'5xl'}} fontWeight={'bold'} color={'tema.cor001'} w={'100%'}>{s.titulo}</Text>
                                        <Text mb={'20px'}  fontSize={{base:'lg',lg:'2xl','2xl':'4xl'}}  color={'tema.cor001'} w={'100%'}>{s.descricao}</Text> 
                                        <Spacer/>
                                        <NavLink to={s.link}>
                                            <Bt1 bg1={typeof s.cor !=='undefined'?s.cor.btbg1:'#000000'} bg2={typeof s.cor !=='undefined'?s.cor.btbg2:'#000000'} >{s.linkTitulo}</Bt1>
                                        </NavLink>  
                                    </Stack>
                                    <Image   zIndex={1} top={'0px'} opacity={0.7} minW={'fit-content'}  h={'100%'} right={'0px'} position='absolute' src={"/assets/"+cfg.site+"/img/slideHome/"+s.img} />
                                </Box> 
                            )
                        })
                    }   
                </Flex>
            </Box>
            {/* {dados.theme.bgImg!==''?<Box  zIndex={1} pos={'absolute'} top={'0px'} left={'0px'} w='100%' h="100%" overflow={'hidden'}><Img src={'/assets/'+cfg.site+''+dados.theme.bgImg} h={'100%'} w={'100%'} opacity={'0.6'}/></Box>:null} */}
            
            {/* <Box zIndex={100} position={'absolute'} bottom='0px' left={'0px'} h={'4px'} bg={'blue'} w={bar+'vw'} transition={(bar==0?'0':'8s')}> teste33</Box>  */}
        </Flex>
    );
} 
export default Slide001;