import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Grid, GridItem, Icon, Image, Input, InputGroup, InputLeftElement, SimpleGrid, Spacer, Stack, Text } from "@chakra-ui/react";
import { FaFire, FaGlobe, FaHistory, FaLevelUpAlt, FaRecordVinyl, FaSearch, FaStar } from "react-icons/fa";  
import { motion } from "framer-motion";
const MenuGeral = function(){
    
    return (
        <Stack bg='tema.bg002' p={'0px 10px'} h={'100%'}>
            {/* <Box w='100%'    pt={'10px'} >
                <Grid     w={'100%'} gap={'10px'} px={'0px'}  templateColumns= {{base :'repeat(2, 1fr)'}}  >
                    {btDestaque.map((t,i)=>{
                        return (
                            <GridItem key={'titulo'+i}  color={'tema.cor001'}  colSpan={1}     textAlign={'center'} p={'0'}>
                                <Button w='100%' h='95px' bg='tema.bg001' transitionDelay={'0.5s'} flexDirection={'column'} gap={'10px'} variant={"ghost"}  colorScheme="whiteAlpha" > 
                                    <Icon as={t.icon} h={'30px'} w={'30px'} />
                                    <Text>{t.nome}</Text>
                                </Button> 
                            </GridItem>
                        )
                    })} 
                </Grid>
                
            </Box>
            <Button w='100%' h='65px' bg='tema.bg001' transitionDelay={'0.5s'} flexDirection={'row'} gap={'10px'} variant={"ghost"}  colorScheme="whiteAlpha" > 
                <Icon as={FaRecordVinyl} h={'30px'} w={'30px'} />
                <Text>Recordes de Apostas</Text>
            </Button> 
            <Box w='100%' borderRadius='10px'   >
                <Grid  py='5px'  w={'100%'}     templateColumns= {{base :'repeat(2, 1fr)'}}  gap='10px' >
                    {btInfo.map((t,i)=>{
                        return (
                            <GridItem key={'titulo'+i}  color={'tema.cor001'}  colSpan={1}     textAlign={'center'} p={'0'} > 
                                <Button as={motion.button} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} position={'relative'} w='100%' h='60px'   alignItems={'flex-start'}  colorScheme={t.cor} p='10px' pl={'10px'}  transform={'background-color 1s ease 5s'} >                
                                    <Text fontFamily={'Segoe'} fontWeight={'bold'} textShadow={'xl'} fontSize={'10px'} color={'tema.cor001'}>{t.nome}</Text> 
                                    <Spacer/>
                                    <Image bottom={'0px'} right={'0px'} position={'absolute'} src={'/assets/menu/'+t.img+'.webp'} h={'40px'}  />
                                </Button> 
                            </GridItem>
                        )
                    })} 
                </Grid>
                
            </Box>
            <Button w='100%' h='60px'  as={motion.button} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}   gap={'10px'} variant={"solid"}  colorScheme="gdCyan" p='0px' pl={'20px'} >                
                <Text fontFamily={'Segoe'} fontWeight={'bold'} textShadow={'lg'} fontSize={'18px'}>AGENTE</Text>
                <Spacer/>
                <Image src={'/assets/menu/agente.webp'} h={'55px'}mt={'5px'}  />
            </Button>  */}
        </Stack> 
    )
}
export default MenuGeral;