export let openB = false;
export let betD  = {
    up  : 0,
    dados:[]
}
export function addBet(d,tipo,jogo,link){  
    var temp = [];
    var novo = true;
    betD.dados.forEach((ds)=>{
        if(ds.d.i==d.i){
            novo = false;
        }else{
            temp.push(ds);
        }
    });
    betD.up = new Date().getTime();
    if(novo){
        

        betD.dados.push({
            d,tipo,jogo,link
        })
    }else{
        betD.dados = temp;
    }  
} 
export function limparBet( ){   
        betD.dados = []; 
} 
export function setOpenB(v){
    openB = v
};
