 
import { Box, Flex, GridItem, SimpleGrid, Stack } from "@chakra-ui/react";
import Slides from "../../../components/Slider";
import dSlide1 from '../../../dados/dSlide1.json'
import { cfg } from "../../../dados/dados";
import MenuLigas from "../../../menu/modelos/MenuLigas";
import MenuBusca from "../../../menu/modelos/MenuBuscar";
import MenuEsportesLigas from "../../../menu/modelos/MenuEsportesLigas"; 
import { esp,cat,jog, setEsp, setCat, setJog, GP } from "../../../conn/WebPainel";
import ListaGeralJogos from "./ListaGeral"; 
import DestaqueEsportes, { DestaqueAoVivo, DestaqueBanner } from "../../../components/Site/EsportesDestaque";
import BoletimAposta from "../../../components/Site/BoletimAposta";
import DetalheJogo from "./DetalheJogo";
 const PageEsporte=(props)=>{  
    const {page=[]} =props;  
    try {setEsp((typeof page[0] == 'undefined'?null:page[0].split('_')[1]))} catch (error) {setEsp(null)}; 
    try {setCat((typeof page[1] == 'undefined'?null:page[1].split('_')[1]))} catch (error) {setCat(null)}; 
    try {setJog((typeof page[2] == 'undefined'?null:page[2].split('_')[1]))} catch (error) {setJog(null)}; 
    console.log(esp,cat,jog);
    const SlideView =Slides.SlideHome; 
    return <Box pb={{base:'50px',xl:'0px'}}> 
        <Flex  className="boxScroll">
            <SlideView dados ={dSlide1} />
        </Flex> 
        <Flex w={'100%'} direction={{base:'column',md:'row'}} minH={'calc(100vh - '+cfg.theme.menu.scroll.top+'px)'}   className="boxScroll" px={{base:'0',md:'20px'}} >
            <Stack w={{base:'100%',md:'230px',xl:'300px'}} h={'100%'}   gap={'20px'}>
                <MenuBusca/>
                <MenuLigas/>
                <MenuEsportesLigas/>  
            </Stack>
            <Stack   w={{base:'100%',md:'calc(100% - 230px)',xl:'calc(100% - 570px)'}} h={'100%'}  px={{base:'0',md:'20px'}}>
                <DestaqueEsportes />
                <DestaqueBanner/>  
                {(esp!=null && cat!=null && jog!=null)?
                        <DetalheJogo jog={jog}  />
                    :<>
                        <ListaGeralJogos aovivo={true}  titulo='Ao Vivo Agora!'/>
                        <ListaGeralJogos aovivo={false} titulo='Próximos Jogos!'/>
                    </>
                }
                
                
            </Stack>
            <Stack zIndex={'50'} position={{base:'fixed',xl:'relative'}} bottom={{base:'0px',xl:'auto'}} right={{base:'0px',xl:'auto'}} w={{base:'250px'}} h={{base:'fit-content',xl:'100%'}}   gap={0}>
                <BoletimAposta/>
            </Stack> 
        </Flex> 
    </Box>
}
export default  PageEsporte;