import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Icon, Image, Skeleton, Stack, Text } from "@chakra-ui/react";
import { FaBasketballBall,  FaGlobe,  FaVolleyballBall } from "react-icons/fa";  
import { FaBeachTennis } from "../../components/Icons/Esportes";
import { GP } from "../../conn/WebPainel";
import { useEffect, useState } from "react";
import { GiSoccerBall} from "react-icons/gi";
import { NavLink } from "react-router-dom";
import { BsPlayCircleFill } from "react-icons/bs";
import { BiSolidTennisBall } from "react-icons/bi";

const SubLigaMenu = function(){
    return (
        <Accordion allowMultiple  >
                <AccordionItem border={'0'} color={'tema.cor001'}  p={'0px'}  >
                    <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'}  _hover={{bg:'tema.bgh003'}} > 
                        <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                        <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                            Brazil
                        </Box> 
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel p={'0px'} pb={'0px'}>
                         <Stack borderRadius={'0'}   overflow={'hidden'} gap={'0px'}>
                            <AccordionButton  px={'10px'}  gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bg003'}} backdropFilter={'sepia(90%)'}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 1
                                </Box> 
                            </AccordionButton> 
                            <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bg003'}}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 2
                                </Box> 
                            </AccordionButton> 
                            <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bg003'}}>
                                <Icon as={FaGlobe} w={'20px'} h={'16px'} borderRadius={'sm'} py={'3px'}  bg={'tema.cor003'}/>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Brazil - Competição 3
                                </Box> 
                            </AccordionButton> 
                         </Stack> 
                    </AccordionPanel>
                </AccordionItem> 
        </Accordion>
    )
};
export const getEsporteIcon=function(id){
    let iconNome = FaGlobe;
    switch(id){
        case 'play':
            iconNome = BsPlayCircleFill;
        break;
        case 100:
            iconNome = FaVolleyballBall
        break;
        case 101:
            iconNome = FaBasketballBall
        break;
        case 102:
            iconNome = BiSolidTennisBall  
        break;
        case 103:
            iconNome = FaBeachTennis
        break;
        case 1:
            iconNome = GiSoccerBall
        break;
    }
    return iconNome;
}
const MenuEsportesLigas = function(){
    const [reload,setReload] = useState(0);   
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(()=>{
        var inter = setInterval(()=>{ 
            if(typeof GP.esporte!=='undefined' && reload!=GP.esporte.up){
                setReload(GP.esporte.up); 
            } 
        },300)
        return ()=>{
            clearInterval(inter);
        }
    },[reload])
    let dataE = null
    try {
        dataE = Object.values(GP.esporte.dados).sort((a, b)=>{  
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0; 
        });
    } catch (error) { 
    }
     
    var open = [] 
    if(window.innerWidth>500){
        open=[0]
    } 
    return (
        <Box  borderRadius='10px'  bg='tema.bg002' >
            <Accordion  allowToggle={true}  defaultIndex={open}  >
                <AccordionItem border={'0'} color={'tema.cor001'}  p={'0px'}  >
                    <h2 >
                        <AccordionButton  px={'10px'} > 
                            <Box as="span" flex='1' p={'5px 10px'} textAlign='left'>
                                ESPORTES
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel p={'6px'} pb={'8px'}>
                         <Stack borderRadius={'lg'} overflow={'hidden'} gap={'2px'}> 
                            {dataE==null?
                                [1,2,3,4,5].map((i)=>{
                                    return   <Skeleton opacity={'0.3'}  key={'esp'+i} h={'40px'}> 

                                    </Skeleton>
                                }):
                                dataE.map((e,i)=>{
                                    return (
                                        <NavLink key={'espN'+e.id} to={'/sport/'+e.slug}  w='full' >
                                            <Button     gap={'10px'} bg={'tema.bg003'} w='full' _hover={{bg:'tema.bgh003'}}  borderRadius={0}>
                                                <Icon as={getEsporteIcon(e.id)} w={'18px'} h={'18px'} color={'tema.cor002'} borderRadius={'sm'}    />
                                                <Box as="span" flex='1' textAlign='left'  color={'tema.cor001'}>
                                                    {e.nome}
                                                </Box> 
                                            </Button>
                                        </NavLink> 
                                    )
                                })
                            }
                             
                            {/* <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bgh003'}}>
                                <Icon as={FaVolleyballBall} w={'18px'} h={'18px'} borderRadius={'sm'}    />
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    Vôlei
                                </Box> 
                            </AccordionButton>  */}
                            {/* <AccordionButton  px={'10px'} gap={'10px'} bg={'tema.bg003'} _hover={{bg:'tema.bgh003'}}>
                           
                                 <Icon as={FaBeachTennis}  w={'18px'} h={'18px'} borderRadius={'sm'} ></Icon>
                                <Box as="span" flex='1' textAlign='left' color={'tema.cor001'}>
                                    BeachTennis
                                </Box> 
                            </AccordionButton>  */}
                         </Stack>
                        
                    </AccordionPanel>
                </AccordionItem> 
            </Accordion> 
        </Box>
    )
}
export default MenuEsportesLigas;