import { useEffect, useState } from "react";
import { GP, cat, esp, jog, srvTime } from "../../../conn/WebPainel";
import { Box, Button, Flex, Icon, Skeleton, Stack, Text } from "@chakra-ui/react";
import { getEsporteIcon } from "../../../menu/modelos/MenuEsportesLigas";
import { cfg } from "../../../dados/dados";
import { hexToRGB } from "../../../Function";
import BoxApostaLista from "./BoxApostaLista";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";

const ListaGeralJogos = function(props){
    var flags = {};
    const [limite, setLimite] = useState(5);
    getCountries().forEach((flag)=>{
        flags[getCountryCallingCode(flag)]=flag;
    });
    const {bgT='tema.cor002',aovivo= false, titulo=''}=props
    const [reload,setReload] = useState(0);   
    const [width, setWidth] = useState(window.innerWidth);
    let lista = null; 
    let jogos = null; 
    useEffect(()=>{
        var inter = setInterval(()=>{ 
            if(typeof GP.esporte!=='undefined' && typeof GP.partidas!=='undefined' && typeof  GP.aposta!=='undefined' && typeof  GP.market!=='undefined' && typeof  GP.position!=='undefined' && typeof  GP.odds!=='undefined'){
                if( reload!=(GP.esporte.up+GP.partidas.up+GP.aposta.up+GP.market.up+GP.position.up+GP.odds.up)){
                    setReload((GP.esporte.up+GP.partidas.up+GP.aposta.up+GP.market.up+GP.position.up));
                } 
            } 
        },300)
        return ()=>{
            clearInterval(inter);
        }
    },[reload])

    const [up,setUp] = useState();
    const [dados,setDados] = useState();
    // useEffect(()=>{ 
    //     var interV = setInterval(()=>{
         
    //             if((GP.aposta.up+GP.market.up+GP.position.up) !== up){
    //                 GP.aposta.up = (GP.aposta.up+GP.market.up+GP.position.up)
                   
                    
                    
    //                 // setDados(temp);
    //             } 
    //         } 
    //     return()=>{
    //         clearInterval(interV)
    //     }
    // },[])



    let bets ={};
    if(typeof GP.esporte!='undefined' && typeof GP.partidas!='undefined' && typeof  GP.aposta!=='undefined' && typeof  GP.market!=='undefined' && typeof  GP.position!=='undefined'){ 
        lista =Object.values(GP.esporte.dados); 
        lista=lista.sort((a, b)=>{  
            return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0; 
        });
        jogos = [];
        var add = true;
       
        Object.values(GP.partidas.dados).forEach((j)=>{
            add = false; 
            if(aovivo){
                if(new Date(j.data).getTime()<srvTime){
                    add=true;
                }
            }else{
                if(new Date(j.data).getTime()>srvTime){
                    add=true;
                }
            }
           if(add){ 
                jogos.push(j);
           }
        })
        jogos.sort((a, b)=>{  
            return a.data < b.data ? -1 : a.data > b.data ? 1 : 0; 
        }); 
        Object.values(GP.aposta.dados).forEach((v)=>{
            if(typeof bets[v.codjog] =='undefined'){
                bets[v.codjog] = [];
            } 
            bets[v.codjog].push({
                i   :   v.id, 
                t   :   v.codmar, 
                cp  :   v.codpos,
                m   :   GP.market.dados[v.codmar].nome,
                p   :   GP.position.dados[v.codpos].nome,
                o   :   GP.position.dados[v.codpos].ordem,
            })
        });
    } 
    return (
        <>
            <Stack bg={'tema.bg002'} borderRadius={'lg'}   gap={0}>
                <Flex bg={bgT} p={'5px 20px'} w={'100%'}   align={'center'} justify={'space-between'} borderTopRadius={'lg'}>
                    <Text as='h2'  fontWeight={'bold'} color={'tema.cor001'} fontFamily={'Barlow'} textTransform={'uppercase'}>{titulo}</Text> 
                </Flex>
                <Box w='100%'  overflowY={'hidden'} overflowX={'auto'} p='10px 5px' pb='5px' gap={'5px'}>
                    <Flex  w={'max-content'} minW={'100%'}  gap={'5px'}>
                        {
                            lista==null? 
                                <Skeleton opacity={'0.3'} w='100%'  h={'30px'}></Skeleton> 
                            :
                            lista.map((l,i)=>{
                                return (
                                    <Button key={'li'+i} h={'30px'} alignSelf='center' justifySelf={'center'} bg={'rgba('+hexToRGB(cfg.theme.cores.bg003)+',0.5)' } flexDirection={'row'} p={'5px 10px'} gap={'10px'} colorScheme="orange" variant={'ghost'} color={'tema.cor001'} _hover={{color:'tema.bg001',bg:'tema.cor001'}}>
                                        <Icon as={getEsporteIcon(l.id)} h={'18px'} color={'tema.cor002'} w={'18px'}  ></Icon>
                                        <Text fontSize={'10px'}  fontWeight={'500'}  >{l.nome}</Text>
                                    </Button>
                                )
                            })
                        }  
                    </Flex> 
                </Box> 
                <Box w='100%' maxH={'60vh'}  overflowY={'auto'} overflowX={'hidden'} p='5px' gap={'5px'}>
                    <Flex direction={'column'}  width={'1m0%'} h={'max-content'}  gap={'5px'}>
                        {
                            jogos==null? 
                                <>
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton> 
                                    <Skeleton opacity={'0.3'} w='100%'  h={'81px'}></Skeleton>  
                                </>
                            :
                            jogos.length>0?
                            jogos.map((j,i)=>{
                                if(i<limite){
                                    return (
                                        <Stack key={'jogo'+i} w={'100%'} h={'auto'} bg={'tema.bg003'} borderRadius={'lg'} p={'0'} >
                                            <BoxApostaLista jogo={j} flags={flags} oddTs ={bets[j.id]}/>
                                        </Stack> 
                                    )
                                }else{
                                    return null
                                }
                               
                            }):
                            <Stack   w={'100%'} h={'auto'} py={'10px'}  borderRadius={'lg'} p={'0'} >
                                <Text color={'tema.cor002'}>Nenhum jogo no momento</Text>
                            </Stack>
                                     
                        }  
                    </Flex> 
                </Box>
            </Stack>
            <Stack bg={'tema.bg002'} borderRadius={'lg'}   gap={0}>
                 
            {limite>5?
                <Button w={'full'} onClick={()=>{
                    setLimite(5)
                }} h={'40px'} colorScheme="whiteAlpha" bg={'transparent'}> Ocultar Todos</Button>
                :
                <Button onClick={()=>{
                    setLimite(200)
                }}  w={'full'} h={'40px'} colorScheme="whiteAlpha" bg={'transparent'}> Mostrar Todos</Button>
            }
            </Stack>
        </>

    )
}
export default ListaGeralJogos;