import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Icon, Input, InputGroup, InputLeftElement, Stack, Text } from "@chakra-ui/react";
import { FaGlobe, FaLevelUpAlt, FaSearch } from "react-icons/fa"; 
const MenuBusca = function(){
    return (
        <Box w='100%' borderRadius='10px'  bg='tema.bg002' >
           <InputGroup size='lg'>
                <InputLeftElement width='3rem'>
                    <Icon as={FaSearch} boxSize={5}  color="tema.cor001"/> 
                </InputLeftElement>
                <Input
                    pl='3rem' 
                    placeholder="Digite o nome do time ou liga"
                    bg={'transparent'}
                    border={0}
                    name="login"
                    fontSize={'14px'}
                    color={'tema.cor001'}
                    _placeholder={{
                        opacity:0.6
                    }}
                /> 
            </InputGroup>
        </Box>
    )
}
export default MenuBusca;